.productitem-container-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.productitem-img-score-item {
  width: 100%;
  height: 87%;
  display: flex;
  position: relative;
}

.productitem-img-item {
  width: 100%;
  object-fit: cover;
  border-radius: 7px;
  height: 100%;
}

.productitem-score-container-border-item {
  background-color: white;
  width: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 17px;
  color: white;
  border-radius: 5px 5px 5px 5px;
  border: 2px solid white;
  /*   box-shadow: 0 0 1px rgba(0, 0, 0, 0.4); */
}

.productitem-score-container-item {
  width: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
}

.productitem-score-container-item.A {
  background-color: #59c0e9;
}
.productitem-score-container-item.B {
  background-color: #00a2c9;
}
.productitem-score-container-item.C {
  background-color: #00a3b2;
}
.productitem-score-container-item.D {
  background-color: #006e8f;
}
.productitem-score-container-item.E {
  background-color: #002a69;
}
.productitem-score-container-item.F {
  background-color: #001c4a;
}

.productitem-texts-item {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.productitem-texts-item-white {
  padding-left: 3px;
  color: white;
  text-shadow: 1px 1px 1.6px rgba(0, 0, 0, 1), 2px 2px 4px rgba(0, 0, 0, 0.6);
  max-width: calc(100% - 30px);
}

.productitem-name-item {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #40454B;
    width: 100%; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.productitem-brand-item {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    color: #666666;
    width: 100%; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.productitem-name-item-white {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: white;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.productitem-brand-item-white {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: white;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*   text-shadow: 1px 1px 1.6px rgba(0, 0, 0, 1),
  2px 2px 4px rgba(0, 0, 0, 0.6); */
}

.productitem-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bottom-info-container-product-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.productitem-score-container-border-item {
  background-color: white;
  width: 20px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 17px;
  color: white;
  border-radius: 5px 5px 5px 5px;
  border: 2px solid white;
  /*   box-shadow: 0 0 1px rgba(0, 0, 0, 0.4); */
}

@media (min-width: 769px) {
  .productitem-score-container-border-item {
    /*     width: clamp(19.3px, 2.4vh + 1rem, 30.4px);
    height: clamp(23.2px, 3.2vh + 1rem, 38px); */
    width: clamp(23.16px, 2.88vh + 1.2rem, 36.48px);
    height: clamp(27.84px, 3.84vh + 1.2rem, 45.6px);
    border-radius: 7px;
    padding: clamp(0.3px, 0.1vh + 0.1rem, 1px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid white;
    border-bottom-right-radius: 0;
  }

  .productitem-img-score-item {
    width: 100%;
    height: 80%;
    display: flex;
  }

  .productitem-score-container-item {
    width: 100%;
    height: 100%;
    border-radius: 6.2px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /*  font-size: clamp(10px, 1vh + 0.7rem, 20px); */
    font-size: clamp(12px, 1.2vh + 0.84rem, 24px);
  }

  .productitem-name-item-white {
    /*     font-size: clamp(12px, 1vh + 0.5rem, 14px); */
    font-size: clamp(14.4px, 1.2vh + 0.6rem, 16.8px);
    padding-right: 2px;
  }

  .productitem-brand-item-white {
    font-size: clamp(12px, 0.4vh + 0.4rem, 14px);
  }

  .productitem-texts-item-white {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 50px);
  }

  .productitem-texts-item {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 3px;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 10px);
  }
  
  .productitem-name-item {
    font-family: "Roboto", sans-serif;
    font-size: clamp(11px, 1.1dvw, 14px);
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .productitem-brand-item {
    font-family: "Roboto", sans-serif;
    font-size: clamp(10px, 1dvw, 12px);
    color: black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
}
