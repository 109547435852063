.div-careinstructions-v2 {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}

.careinstruction-container-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5em;
}

.careinstruction-img {
  width: 3.8dvh;
  height: 3.8dvh;
}

.careinstruction-description-v2 {
  color: #40454b;
  font-size: clamp(9px, 1.8dvh, 13px);
}

@media (min-width: 769px) {
  .careinstruction-description-v2 {
    color: #40454b;
    font-size: clamp(11px, 1.3dvw, 14px);
   /*  font-size: clamp(13px, 2.1dvh, 18px); */
  }

  .careinstruction-img {
    width: 3.4dvh;
    min-width: 30px;
    min-height: 30px;
    height: 3.4dvh;
    filter: grayscale(0.5) opacity(0.5);
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .careinstruction-description-v2 {
    font-size: 11px;
  }

  .careinstruction-img {
    width: 20px;
    height: 20px;
  }
}
