/* container da Product page */
.product-fixed-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100dvh;
}

.swipe-main-container {
  width: 100%;
  height: 100%;
  max-width: 900px;
}

.swipe-pages-container {
  width: 100%;
  height: 100%;
}

/* --------------------------------- */

.page1-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.page2-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.page3-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.page4-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 0.8em;
}



/* header -> logo */
.product-header {
  display: flex;
  width: 98%;
  max-width: 890px;
}
/* -------------- */

.product-maindiv-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3em;
}

@media screen and (max-height: 700px) {
  .product-maindiv-page {
    gap: 1.5em;
  }
}


/* div de cima com a seta */
.product-content-up {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow-content-up {
  width: 20px;
  height: 15px;
  transform: rotate(180deg);
  opacity: 0;
  animation: contentAppear 0.4s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}
/* ----------------- */





/* div de baixo com a seta */
.product-content-down {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.arrow-content-down {
  width: 20px;
  height: 15px;
}
/* ----------------------- */




/* maindiv */
.product-maindiv-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: 100%;
  gap: 3dvh;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}

@media screen and (max-height: 736px) {
  .product-maindiv-container {
    gap: 10px;
  }
}

.product-maindiv-slide {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* ----------------------- */



/* div das escalas numericas SAC e SES */
.div-scales-sac-ses {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.9em;
  opacity: 0;
  animation: contentAppear 0.3s ease-in forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}

@media screen and (max-height: 755px) {
  .div-scales-sac-ses {
    gap: 1em;
  }
}
/* --------------------- */



/* div do detailedIndex - grelha */
.div-detailedIndex {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  width: 98%;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}

.div-detailedIndex-title {
  color: #40454B;
  font-size: 15px;
  font-weight: bold;
  font-variant: small-caps;
}

.div-detailedIndex-content {
  width: 100%;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

@media screen and (max-height: 755px) {
  .div-detailedIndex-content {
    gap: 0.5em 1.2em;
  }

  .div-detailedIndex {
    gap: 0.5em;
  }
}
/* ----------------------------- */



/* div do journey - grelha */
.div-journey {
  width: 98%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5%;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}

.div-journey-title {
  color: #40454B;
  font-size: 15px;
  font-weight: bold;
  font-variant: small-caps;
}

.div-journey-content {
  width: 100%;
  height: 85%;
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(0, 1fr);
  gap: 1em;
}

.div-journey-productionActivity-component {
  display: flex;
  align-items: center;
  height: 80%;
}

@media screen and (max-height: 740px) {
  .div-journey-content {
    gap: 1em 0em;
  }

  .div-journey {
    gap: 0.5em;
  }
}

/* ----------------------------- */

.product-maindiv-page4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4dvh;
}

/* div do careinstructions - list */
.div-careinstructions {
  width: 96%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}

.div-careinstructions-title {
  color: #40454B;
  font-size: 1.75dvh;
  font-weight: bold;
  font-variant: small-caps;
}

.div-careinstructions-content {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
/* ----------------------------- */

.product-maindiv-slide-longtext {
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}

.product-maindiv-page4-footer {
  width: 88%;
  margin-bottom: 0.2em;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
to {
    opacity: 1;
}
}