.scales-div {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.letter-scale-container {
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
  }
  
  .letter-scale {
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #005000 0%, #005000 15%, #00CC00 15%, #00CC00 25%, #FFDE69 25%, #FFDE69 35%, #FFB366 35%, #FFB366 45%, #FF8000 45%, #FF8000 55%, #FF0000 55%, #FF0000 100%);
    position: relative;
  }

  .letter-mark {
    position: absolute;
    height: 10px;
    width: 2px;
    background-color: #40454B;
    transform: translateY(-35%);
    margin-left: -1px;
  }
  
  .letter-mark-text {
    font-size: 7px;
    font-weight: 600;
    color: #40454B;
    margin-left: -5px;
    margin-top: 10px;
  }
  
  .letter-mark:first-child {
    left: 0;
  }
  
  .letter-mark:last-child {
    left: 100%;
  }


.letter-arrow {
    position: absolute;
    left: 0;
    top: -12px;
    width: 6px;
    height: 6px;
    background-color: #D1D1D1;
    clip-path: polygon(0 50%, 0 0, 100% 0, 100% 50%, 50% 100%);
  }

.letter-arrow-score {
    border: 2px solid;
    border-radius: 50px;
    position: absolute;
    left: 0;
    top: -40px;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 1.2em;
    color: #030;
    font-size: 18px;
    font-weight: bold;
    font-variant: small-caps;
  }

@media screen and (max-width: 312px) {
  .letter-scale-container {
    width: 250px;
  }

  .letter-arrow-score {
    top: -38px;
    width: 20px;
    height: 20px;
    line-height: 1.10em;
    font-size: 17px;
}
}
  
  

  