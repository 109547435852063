.footer-container {
/*     position: fixed;
    bottom: 0; */
    font-family: 'Roboto', sans-serif;
    height: clamp( 80px, 17dvh, 1px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 -1px 1px #0000001A;
    background-color: white;
    user-select: none;
}

.footer-row1 {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 1%;
    padding-top: 0.8%;
}

.footer-row1-left {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
    gap: 30px;
}

.footer-row1-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 10px;
}


.footer-row1-left-cols {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.footer-row1-left-cols.faqs {
    font-family: 'Roboto', sans-serif;
    font-size: clamp(12px, 1dvw, 16px);
    font-weight: bold;
    color: #40454B;
    /* text-decoration: underline; */
    /* cursor: pointer; */
}

.footer-titles {
    font-family: 'Roboto', sans-serif;
    font-size: clamp(12px, 1dvw, 16px);
    font-weight: bold;
    color: #40454B;
}

.footer-texts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:5px;
}

.footer-texts {
    font-family: 'Roboto', sans-serif;
    font-size: clamp(10px, 0.8dvw, 14px);
    font-weight: lighter;
    color: #40454B;
}

.footer-texts.click {
    /* cursor: pointer; */
}

.footer-texts.click:hover {
    /* text-decoration: underline; */
}

.footer-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.icon-socials-container {
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.icon-socials {
    width: 1.7dvw;
    min-width: 20px;
    max-width: 28px;
    height: auto;
    /* cursor: pointer; */
}

.footer-sponsors-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.icon-sponsors-v2 {
    min-width: 400px;
    width: 32dvw;
    max-width: 480px;
    height: auto;
}

.footer-row2 {
    width: 98%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.4%;
    padding-bottom: 0.8%;
    box-shadow: 0 -1px 1px #9191911a;
}

.terms-privacy-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer-row2-texts {
    font-family: 'Roboto', sans-serif;
    font-size: clamp(9px, 0.65dvw, 12px);
    font-weight: normal;
    color: #929292;
}

.footer-row2-texts.click {
    /* cursor: pointer; */
}

.footer-row2-texts.click:hover {
    /* text-decoration: underline; */
}