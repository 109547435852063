.language-selection {
  width: 44px;
  background-color: transparent;
  margin-top: auto;
  margin-bottom: auto;
}

.language-selection.open {
  position: static;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #0000001a;
}

.language-selection-button {
  background-color: transparent;
  /* cursor: none; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /*   padding-bottom: 8px; */
}

.language-selection-selected::after {
  content: ".";
  position: absolute;
  bottom: -0.4em;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1em;
}

.language-selection-selected-container {
  display: flex;
  align-items: center;
  position: relative;
}

.language-selection-selected {
  font-family: "Roboto";
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  color: #40454b;
  position: relative;
}

.language-selection-selected.homepage {
  color: #ffffff;
}

.img-close.closed {
  transform: rotate(180deg);
}

.img-close {
  position: absolute;
  left: 110%;
  top: 0;
}

.img-close.closed.homepage {
  filter: brightness(1000%);
}

.img-close.homepage {
  filter: brightness(1000%);
}

.icon-search-desktop {
  height: 17px;
  width: 17px;
}

.language-selection-menu {
  position: absolute;
  display: none;
  flex-direction: column;
  width: 20px;
  justify-content: space-between;
  z-index: 1;
  padding: 15px 12px 4px 12px;
  margin: 0;
  list-style: none;
  max-height: fit-content;
  overflow-y: auto;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  z-index: 3;
  background-color: white;
}

.language-selection-menu.homepage {
  margin-top: 3px;
}

.language-selection.open .language-selection-menu {
  display: flex;
}

.separator-languages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
}

.separator-languages-line {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 52%,
    rgba(0, 0, 0, 0) 100%
  );
  border-image-slice: 1;
  width: 100%;
  height: 1px;
}

.language-selection-menu li {
  display: flex;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  justify-content: center;
}

.language-selection-menu li:hover {
  background-color: #f0f0f0;
}
