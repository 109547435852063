.composition-container {
    display: flex;
    flex-direction: column;
    gap: 0.4em;
    align-items: center;
}

.composition-title {
    color: #40454B;
    font-size: 13px;
    font-weight: bold;
    font-variant: small-caps;
}

.composition-map {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.composition-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.composition-item-color-square {
    min-width: 15px;
    height: 15px;
}

.composition-item-value {
    color: #113678;
    font-size: 13px;
    font-weight: bold;
    font-variant: small-caps;
    min-width: 40px;
    text-align: end;
}

.composition-item-code {
    color: #113678;
    font-size: 12.5px;
    font-weight: bold;
    font-variant: small-caps;
    width: fit-content;
    margin-left: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
}