.navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 45px;
}

.logo {
    width: 100px;
}