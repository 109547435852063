.productitem-container-item-similar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.productitem-img-score-item-similar-v2 {
    width: 100%;
    height: 100%;
    display: flex;
}

.productitem-img-score-item {
    cursor: pointer;
}

.productitem-img-item-similar {
    width: 100%;
    object-fit: cover;
    border-radius: 7px;
    height: 100%;
}

.productitem-score-container-border-item-similar {
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 26px;
    font-family: 'Roboto';
    font-size: 13px;
    color: white;
    border-radius: 6px;
    border: 2px solid white;
}

.productitem-score-container-item-similar {
    width: 20px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 13px;
    color: white;
    border-radius: 5px;
}

.productitem-score-container-item-similar.A {
    background-color: #59c0e9;
}
.productitem-score-container-item-similar.B {
    background-color: #00a2c9;
}
.productitem-score-container-item-similar.C {
    background-color: #00a3b2;
}
.productitem-score-container-item-similar.D {
    background-color: #006e8f;
}
.productitem-score-container-item-similar.E {
    background-color: #002a69;
}
.productitem-score-container-item-similar.F {
    background-color: #001c4a;
}


.productitem-texts-item-similar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.productitem-name-item-similar {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #40454B;
    width: 100%; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.productitem-brand-item-similar {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    color: #666666;
    width: 100%; 
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}