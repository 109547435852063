/* Modal.css */
.modal-overlay {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 40%;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-border {
  width: 95%;
  height: 95%;
  border-radius: 5px;
  border: 2px solid rgba(209, 209, 209, 0.50);
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2px;
}

.modal-title {
  margin-top: 10px;
  color: #40454B;
  font-size: 1.9dvh;
  font-weight: bold;
  font-variant: small-caps;
  padding: 0px 5px;
}

.modal-content {
  margin-top: 10px;
  max-width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  scrollbar-width: 3px; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
  color: #40454B;
  font-size: 1.65dvh;
  font-variant: small-caps;
  text-align: justify;
  padding: 0px 5px;
}


/* For WebKit (Chrome, Safari) */
.modal-content::-webkit-scrollbar {
  width: 3px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.modal-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal-close {
  position: absolute;
  top: 12px;
  right: 7px;
  background: none;
  border: none;
  cursor: pointer;
}
