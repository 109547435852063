.scales-div {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.scale-container {
    width: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
}

  @media screen and (max-width: 340px) {
    .scale-container {
      width: 220px;
  }
  }
  
  .scale {
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, #005000 0%, #005000 15%, #00CC00 15%, #00CC00 25%, #FFDE69 25%, #FFDE69 35%, #FFB366 35%, #FFB366 45%, #FF8000 45%, #FF8000 55%, #FF0000 55%, #FF0000 100%);
    position: relative;
  }

  .mark {
    position: absolute;
    height: 10px;
    width: 2px;
    background-color: #40454B;
    transform: translateY(-35%);
    margin-left: -1px;
  }
  
  .mark-text {
    font-size: 7px;
    font-weight: 600;
    color: #40454B;
    margin-left: -5px;
    margin-top: 10px;
  }
  
  .mark:first-child {
    left: 0;
  }
  
  .mark:last-child {
    left: 100%;
  }

 

  .arrowscore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: -30px;
    width: 50px;
  }

.arrow {
    width: 6px;
    height: 6px;
    background-color: #D1D1D1;
    clip-path: polygon(0 50%, 0 0, 100% 0, 100% 50%, 50% 100%);
  }

.arrow-score {
    text-align: center;
    line-height: 1.1em;
    color: #030;
    font-size: 18px;
    font-weight: bold;
    font-variant: small-caps;
}


  

  