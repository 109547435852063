.certifications-desktop-container-new {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
  }
  
  .certifications-desktop-title {
    font-size: clamp(13px, 1.7dvw, 16px);
  }
  
  .certifications-desktop-carousel-container {
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.certifications-desktop-carousel-container.selected {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.certifications-desktop-carousel {
  display: flex;
  justify-content: center;
  width: 100%;
}

.stacked-carousel-responsive-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 769px) {
  .material-button-right {
    margin-top: 5px;
  }

  .material-button-right {
    margin-top: 7px;
  }

  .react-stacked-center-carousel-slide--1 {
    transform: translateX(-9.5dvw) scale(1) !important;
  }

  .react-stacked-center-carousel-slide-1 {
    transform: translateX(9.5dvw) scale(1) !important;
  }
}

.react-stacked-center-carousel {
  max-height: 65px !important;
}

  .certification-carousel-item {
  }
  
  .certification-img {
    width: clamp( 35px, 3.6dvw, 60px);
    height: clamp( 35px, 3.6dvw, 60px);
    cursor: pointer;
    filter: saturate(0);
  }
  
  .certification-detail {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8%;
  }
  
  .certification-close-button {
    width: 18px;
    cursor: pointer;
    padding-left: 15px;
  }

.material-button-left {
  width: 100%;
}

.arrow-left-certifications-img {
  height: 20px;
  width: 20px;
}

.arrow-left-certifications {
  display: flex;
  align-items: center;
}

.arrow-right-certifications-img {
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}

.arrow-right-certifications {
  display: flex;
  align-items: center;
}

.no-certifications-message {
  height: 100%;
  font-family: "Roboto";
  font-weight: 400;
  font-size: clamp(11px, 1.3dvw, 14px);
  color: #010101;
  display: flex;
  justify-content: center;
  align-items: center;
}
