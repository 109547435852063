/* Slideshow */

.slideshow-padding {
  overflow: hidden;
  height: 100%;
  padding: 4px 6px;
  
}

.slideshow-shadow {
  overflow: hidden;
  height: 99%;
  padding: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.slideshow {
  overflow: hidden;
  height: 100%;
}

@media screen and (max-height: 745px) {
  .slideshow {
    height: 100%;
  }
}

.slideshowSlider {
    height: 100%;
    display: flex;
    transition: transform 0.8s ease;
}

.slide-v2 {
    display: flex;
    flex: 0 0 100%;
}

.slide-img-v2 {
    width: 100%;
    object-fit: cover;
}

/* Buttons */

.slideshowDots {
    text-align: center;
    position: relative;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .slideshowDot {
    display: inline-block;
    height: 11px;
    width: 11px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0px 7px 0px;
    background-color: #F7F7F7;
  }
  
  .slideshowDot.active {
    background-color: #113678;
  }
