@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.container-detailedIndex {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.container-detailedIndex-desktop {
  width: 96%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
  justify-content: space-between;
  overflow-y: hidden;
  min-height: 60dvh;
  max-height: 80dvh;
}

.container-detailedIndex.has-expanded {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detailexIndex-item-v2 {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.detailexIndex-item-v2-desktop {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
}

.description-preview {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.description-preview-space {
  width: 40px;
}

.decription-preview-content-v2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-size: 13px;
}

@media (max-width: 300px), (max-height: 680px) {
  .decription-preview-content-v2-p {
    font-size: 13px;
  }
}

@media screen and (min-width: 769px) {
  .description-preview-space {
    width: 0px;
  }

  .container-detailedIndex-desktop {
    width: 96%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
    justify-content: space-evenly;
    overflow-y: hidden;
    min-height: 60dvh;
    max-height: 80dvh;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .decription-preview-content-v2-p {
    font-size: 11px;
  }
}
