.composition-container-simple {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.composition-line-simple {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-of-donut-container-simple {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}