.longtext-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    height: 29dvh;
    gap: 0.5em;
}

.longtext-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #40454B;
    font-size: 1.75dvh;
    font-weight: bold;
    font-variant: small-caps;
}

.longtext-text {
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #40454B;
    font-size: 1.6dvh;
    font-variant: small-caps;
    text-align: justify;
}

.longtext-text.centered-text {
    display: flex;
    justify-content: center;
}


.see-more-button {
    color: #747474;
    font-size: 13px;
    font-variant: small-caps;
    position: absolute;
    bottom: 0;
    border: none;
    width: 100%;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0.0) 0%, rgb(236, 236, 236) 70%);
    height: 7dvh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
  }
  
.see-more-button-eye-img {
    width: 20px;
}
