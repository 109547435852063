.zara-tabs-container {
    width: 92.12%;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    color: #666666;
    font-weight: 400;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    pointer-events: auto;
  }
  
  .zara-tabs {
    width: fit-content;
    display: flex;
    flex-direction: row;
  }
  
  .zara-tab {
    width: 140px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 3px;
    border-bottom: 1px solid #66666640;
  }
  
  .zara-tab.active {
    border-bottom: 1.4px solid #666666;
  }
  
  .zara-tabs-container::-webkit-scrollbar {
    display: none;
  }
  
  .zara-tabs-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }



  @media screen and (min-width: 769px) {
    .zara-tabs-container {
      width: 97%;
      font-family: "Roboto", sans-serif;
      font-size: clamp(13px, 1.7dvw, 16px);
      color: #666666;
      font-weight: 300;
      overflow: auto;
      cursor: grab;
    }

    .zara-tabs-container.grabbing {
      cursor: grabbing;
    }
    
    .zara-tabs {
      width: fit-content;
      display: flex;
      flex-direction: row;
    }
    
    .zara-tab {
      width: clamp(150px, 18dvw, 300px);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 3px;
      border-bottom: 1px solid #66666640;
      text-wrap: nowrap;
      user-select: none;
    }
    
    .zara-tab.active {
      border-bottom: 2px solid #666666;
    }
  }

@media screen and (max-width: 768px) and (max-height: 650px) {
    .zara-tabs-container {
      font-size: 12px;
    }
    
    .zara-tab {
      width: 125px;
      text-wrap: nowrap;
    }
}
  