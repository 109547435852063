:root {
  --background-color: #fefefe;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  user-select: none !important;
}


@media screen and (max-width:768px) {
  html {
      overflow: hidden;
      overscroll-behavior: none;
  }
}