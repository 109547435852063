
.properties-full-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.properties-line {
  background-color: #66666640;
  height: 1px;
  margin: 5px;
}

.properties-title-map {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.properties-subtitle {
  font-family: "Roboto";
  color: #666666;
  font-size: 12.5px;
  font-weight: 700;
  text-transform: capitalize;
  padding-left: 8px;
}

.categoria-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px 35px;
  width: 100%;
  height: fit-content;
  /* margin-bottom: 25px; */
}

.categoria-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8px;
}

.item-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.name, .properties-description {
  margin: 0;
  font-family: "Roboto";
  color: #666666;
  text-align: left;
}

.name {
  font-size: 0.8em;
  font-weight: 400;
  line-height: 12px;
  text-transform: capitalize;
}

.properties-description {
  margin-top: 0.3em;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 13px;
}

@media (max-width: 399px) {
  .categoria-container {
    gap: 10px;
  }

  .name {
    font-size: 0.7em;
  }

  .properties-description {
    font-size: 0.8em;
  }
}

@media (min-width: 400px) and (max-width: 480px) {
  /* .categoria-container {
    grid-template-columns: repeat(3, 3fr);
    width: 100%;
    gap: 10%;
  } */

  .name {
    font-size: 0.7em;
  }

  .properties-description {
    font-size: 0.8em;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  /* .categoria-container {
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 10%;
  } */

  .name {
    font-size: 0.7em;
  }

  .properties-description {
    font-size: 0.7em;
  }
}

@media (min-width: 769px) {
  .categoria-container {
    gap: 15px 1dvw;
  }

  .properties-subtitle {
    font-size: clamp(12px, 1.32dvw, 15px);
  }

  .name {
    /* font-size: clamp(13px, 2.1dvh, 18px); */
    font-size: clamp(11px, 1.3dvw, 14px);
    line-height: normal;
  }

  .properties-description {
   /*  font-size: clamp(13px, 2.1dvh, 18px); */
    font-size: clamp(11px, 1.3dvw, 14px);
    line-height: normal;
  }
}

@media (min-width: 1440px) {

  .categoria-container {
    width: 90%;
    gap: 15px 3.5dvw;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .categoria-container {
    gap: 8px;
  }

  .name {
    font-size: 11px;
  }

  .properties-description {
    margin-top: 2px;
    font-size: 11px;
  }

  .properties-subtitle {
    font-size: 11px;
  }
}
