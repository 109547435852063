.productionactivity-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    width: 150px;
    height: fit-content;
}

.productionactivity-type-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.1em;
}

.icon-productionactivity {
    height: 50px;
    width: 50px;
}

.productionactivity-type {
    color: #666;
    font-size: 11px;
    font-weight: bold;
    font-variant: small-caps;
}

.productionactivity-name-country-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productionactivity-name {
    color: #113678;
    font-size: 15px;
    font-weight: bold;
    font-variant: small-caps;
    text-align: center;
}

.productionactivity-country {
    color: #40454B;
    font-size: 13px;
    font-weight: bold;
    font-variant: small-caps;
    text-align: center;
}

@media screen and (max-height: 740px) {
    .productionactivity-container {
        gap: 0em;
        width: 130px;
    }

    .icon-productionactivity {
        height: 40px;
        width: 40px;
    }

    .productionactivity-name {
        font-size: 13px;
    }
    
    .productionactivity-country {
        font-size: 12px;
    }
}