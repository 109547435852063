@media (max-width: 768px) {
  .container-floating {
    z-index: 1052;
  }
  .container.open {
    height: 100%;
    width: 100%;
    z-index: 1001;
    position: fixed;
  }

  .overlay-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    display: none;
  }

  .overlay-menu.visible {
    display: block;
    animation: shadowFadeIn 0.3s ease forwards;
  }

  .overlay-menu.hidden {
    animation: shadowFadeOut 0.3s ease forwards;
  }

  @keyframes shadowFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes shadowFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .floating-menu {
    position: fixed;
    z-index: 1001;
    top: 0;
    right: -180%;
    width: 65%;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease-out;
    border-top-left-radius: 8.33px;
    border-bottom-left-radius: 8.33px;
  }

  .floating-menu.open {
    right: 0;
  }

  .floating-menu-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
  }

  .close-button {
    width: 15px;
    height: auto;
    cursor: pointer;
  /* s */
  }

  .menu-header {
    text-align: left;
    font-size: 14px;
/*     margin-bottom: 20px; */
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16.6667px;
    line-height: 20px;
    color: #666666;
  }

  .language-option {
    top: 0;
    font-size: 16px;
  }

  .language-option.active {
    top:0;
    position: relative;
    cursor: pointer;
    font-weight: bold;
  }

  .language-option.active::after {
    content: '.';
    position: absolute;
    bottom: -0.4em; 
    left: 50%;
    transform: translateX(-50%);
    font-size: 1em; 
  }

  .menu-items {
    list-style: none;
    padding: 0;
    margin-right: 0px;
    margin-top: 35.5px;
    font-size: 24px;
    flex-grow: 1;
  }

  .menu-items li {
    margin: 10px 0;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16.6667px;
    line-height: 20px;
    color: #40454b;
    align-items: center;
    text-align: right;
    text-transform: uppercase;
    margin-bottom: 27.5px;
  }

  .menu-footer {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .supporters-image {
    align-self: flex-start;
    margin-left: 15px;
    width: 85%;
    max-width: 85%;
    height: auto;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .dpp-overlay-image {
    width: 50%;
    max-width: 55%;
    height: auto;
  }
}

.floating-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 480px) and (max-width: 600px)  {
  .floating-menu {
    width:60%;
  }

  .menu-items {
   

  }

  .menu-items li {
    font-size: 17px;
    margin-bottom: 29px;
  }
}

@media (min-width: 600px) and (max-width: 720px)  {
  .floating-menu {
    width:50%;
  }

  .menu-items li {
    font-size: 18x;
    margin-bottom: 30px;
  }
}

/*Pode ir até mais, posteriormente */
@media (min-width: 720px) and (max-width: 768px)  {
  .floating-menu {
    width:44%;
  }

  .menu-items li {
    font-size: 18px;
    margin-bottom: 30.5px;
  }
}

@media (min-width: 769px) {
  .floating-menu {
    display: none;
  }
}
