.search-category-container {
  display: flex;
  flex-direction: row;
  width: calc(100% - 10px);
  height: 90%;
  gap: 15px;
}

.search-category-col1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.search-category-col1-row1 {
  width: 100%;
  height: calc(50% - 6px);
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.search-category-col1-row1-col1 {
  width: 100%;
}
.search-category-col1-row1-col2 {
  width: 100%;
}

.search-category-col1-row2 {
  width: 100%;
  height: calc(50% - 6px);
}

.search-category-col2 {
  width: 100%;
  height: 100%;
}

.search-category-grid-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.search-category-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.65);
}

.search-category-grid-item div {
  color: white;
  font-size: clamp(14px, 1.8dvw, 18px);
  font-weight: normal;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}

.search-category-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-wrap: nowrap;
}

.search-category-grid-item:hover img {
  filter: none;
}

.search-category-grid-item:hover div {
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .search-category-container {
    display: flex;
    flex-direction: row;
    width: 84%;
    height: calc(100dvh - 8dvh - 80px - clamp( 45px, 8.5dvh,72px) );
    gap: 15px;
    min-height: 150px;
  }
}

@media (max-width: 768px) {
  .search-category-col1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0px;
  }

  .search-category-col1-row1 {
    width: 100%;
    height: calc(50% - 7.5px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0px;
  }

  .search-category-col1-row1-col1 {
    width: 100%;
    height: calc(50% - 7.5px);
  }
  .search-category-col1-row1-col2 {
    width: 100%;
    height: calc(50% - 7.5px);
  }

  .search-category-col1-row2 {
    width: 100%;
    height: calc(50% - 7.5px);
  }

  .search-category-text {
    text-wrap: wrap;
    text-align: center;
  }
}
