body {
    margin: 0;
}

/* isto se ficar versao mobile */
/* .home-container {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100dvh - 73px);
  max-width: 900px;
} */

.home-container {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100dvh;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(90%);
}

.slider-container {
  height: calc(100dvh - 90px);
  margin: 0 auto;
}

.navbar-footer {
  display: flex;
}

.homepage-mid-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.logo-dpp-homepage {
  width: 35dvw;
  min-width: 240px;
  max-width: 450px;
}

.homepage-mid-content-text {
  width: 80%;
  max-width: 800px;
  font-family: 'Roboto';
  font-size: 14px;
  color: white;
  text-align: justify;
}

.aboutus-container {
  bottom: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
}

.aboutus-text {
  font-family: 'Roboto';
  font-size: 13px;
  color: white;
}

.aboutus-arrow {
  width: 17px;
}

.navbar-desktop-homepage {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  height: 72px;
  right: 10px;
  top: 0;
  background: transparent;
  z-index: 1001;
}

.navbar-right-desktop-homepage {
  display: flex;
  align-items: center;
}

.navbar-item-desktop-homepage {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #40454b;
  text-decoration: none;
  padding: 0 10px;
  filter: brightness(1000%);
}


@media screen and (min-width: 769px) {
  .home-container {
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: 100dvh;
  }
}