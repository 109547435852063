/* container da Product page */
.product-fixed-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.overlay-product-main-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.overlay-product-main-page-content {
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.icon-overlay-product {
  width: 60px;
  height: 60px;
  filter: brightness(0) invert(1);
  rotate: -90deg;
}

.overlay-product-text1 {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.overlay-product-text2 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: white;
  font-weight: lighter;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.icon-logo-dpp-overlay {
  width: 30px;
  filter: brightness(0) invert(1);
}

.overlay-product-button {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 300;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
}



.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100dvh;
  position: relative;
  border-radius: 10px 10px 0 0;
  margin-top: 3px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
}

/* --------- PAGE INDICATOR -------------- */
@media (max-width: 1024px) {
  .page-indicator {
    position: absolute;
    right: 0.5%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .indicator-dot {
    width: 5px;
    height: 5px;
    background-color: #d9d9d9;
    border-radius: 50%;
  }

  .indicator-dot.active {
    width: 7px;
    height: 7px;
    background-color: #666666;
  }
}

/* --------------------------------------- */

.swipe-main-container {
  width: 100%;
  height: 100%;
  max-width: 900px;
}

.swipe-pages-container {
  width: 100%;
  height: 100%;
}

/* --------------------------------- */

.page1-container-v2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 72.52px);
  align-items: center;
  justify-content: flex-start;
}

.main-container-desktop {
  display: flex;
  width: calc(100% - 20px);
  height: calc(100dvh - calc(12px + clamp(45px, 8.5dvh, 72px)));
  margin-top: calc(10px + clamp(45px, 8.5dvh, 72px));
  padding-left: 10px;
}

.main-container-desktop.footer {
  display: flex;
  width: calc(100% - 30px);
  height: calc(
    100dvh - calc(10px + clamp(45px, 8.5dvh, 72px)) + clamp(80px, 17dvh, 1px)
  );
  margin-top: calc(10px + clamp(45px, 8.5dvh, 72px));
  padding-left: 10px;
}

.page1-container-v2-desktop {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: calc(100dvh - 73px);
  overflow-y: auto;
}

.page-products-navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.product-maindiv-container-v2-desktop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.detailed-index-maindiv-container-v2-desktop {
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  width: 96%;
  max-height: 90dvh;
}

.detailed-index-maindiv-container-v2-desktop {
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  width: 96%;
  max-height: 90dvh;
}

.gallery-container-desktop {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: calc(100% - 16px);
  gap: 10px;
}

.gallery-container-desktop.footer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: calc(100% - 16px - clamp(80px, 17dvh, 1px));
  gap: 10px;
}

.content-container-desktop {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: calc(100% - 16px);
  overflow-y: auto;
}

.content-container-desktop.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: calc(100% - 21px - clamp(80px, 17dvh, 1px));
  overflow-y: auto;
  margin-top: 5px;
}

.gallery-container-desktop-detailed-index {
  display: flex;
  flex-direction: column;
  width: 54%;
  max-height: 90dvh;
}

.composition-desktop {
  width: 95%;
  min-height: fit-content;
}

.title-composition-desktop {
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  color: #010101;
  text-transform: uppercase;
}

.additional-info-container-desktop {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carousel-container-additional {
  display: flex;
  flex-direction: column !important;
}

.detailed-index-rightside-main-div {
  display: flex;
  flex-direction: column;
  width: 44%;
  justify-content: space-between;
}

.additional-info-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0;
  justify-content: space-evenly;
  animation: growWidth 0.5s forwards;
}

/* .slider-maindiv-page5 {
  width: 100%;
} */

.product-rightside-main-div {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  overflow-y: auto;
  overflow-x: hidden;
}

.certifications-container-desktop {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.certifications-container-desktop-new {
  width: 95%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page2-container-v2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 72.52px);
  align-items: center;
  justify-content: flex-start;
}

.page2-container-v2-desktop {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
}

.page2-container-v2-desktop {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: 100dvh;
}

.page3-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 72.52px);
  align-items: center;
  justify-content: center;
}

.page3-container-desktop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.page3-container-desktop {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.page4-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8em;
}

.header-content-v2-desktop {
  width: 96%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-top: 7px;
}

/* header -> logo */
.product-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  max-width: 890px;
}

.header-v2 {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* ------------------------ */

.header-content-v2 {
  width: 96%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-top: 7px;
}

.navbar-footer-product {
  bottom: clamp(-80px, -17dvh, -1px);
  position: absolute;
  width: calc(100% - 10px);
}

.header-content-v2-desktop {
  width: 96%;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-top: 7px;
}

.product-arrow-goback {
  z-index: 999;
  width: 16px;
  height: 100%;
  cursor: pointer;
  padding-right: 4px;
}

@media (max-width: 300px) or (max-height: 680px) {
  .header-content-v2 {
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .product-arrow-goback {
    z-index: 999;
    width: 15px;
    height: 100%;
    cursor: pointer;
  }
}

.product-arrow-goback-desktop {
  z-index: 999;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  left: calc(clamp(70px, 4.3dvw, 85px) + 3.6dvw - 12px);
}

.header-content-v2-content {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 6px;
}

.header-content-v2-content-desktop {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.header-content-v2-content-desktop.center {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.header-content-v2-content.center {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.header-content-v2-content-desktop.center {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.header-content-v2-title {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #40454b;
  font-weight: bold;
}

.header-content-v2-title-desktop {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #40454b;
  font-weight: normal;
}

.header-content-v2-title {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #40454b;
  font-weight: normal;
}

.header-content-v2-title-desktop {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #40454b;
  font-weight: normal;
}

.header-content-v2-text {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: #40454b;
}

.header-content-v2-text-desktop {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: #40454b;
}

.header-content-v2-text-desktop {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  color: #40454b;
}

.product-interrogation {
  width: 17px;
  height: 17px;
}
.container-header-gallery-desktop {
  display: flex;
  flex-direction: column;
}

.header-container-v2-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 0px;
}

.header-content-v2-content-desktop {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  text-wrap: nowrap;
  margin-left: calc(clamp(70px, 4.3dvw, 85px) + 3.6dvw + 2px);
}

.header-content-v2-content-desktop.center {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}
.brand-container-v2-desktop {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  gap: 10px;
}
.made-in-content-v2-text-desktop {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: "Roboto";
  font-size: 14px;
  color: #40454b;
}

.text-inline-desktop {
  display: flex;
  flex-direction: row;
}

.header-content-v2-title-desktop {
  font-family: "Roboto";
  font-style: normal;
  font-size: clamp(14px, 1.5dvw, 18px);
  text-transform: capitalize;
  color: #090909;
}

.header-content-v2-text-desktop {
  font-family: "Roboto";
  font-size: clamp(12px, 1.5dvw, 14px);
  font-style: normal;
  text-align: left;
  color: #40454b;
}

/* ----------------------- */

/* maindiv */
.product-maindiv-container-v2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  opacity: 1;
}

@media screen and (max-height: 736px) {
  .product-maindiv-container-v2 {
    gap: 10px;
  }
}

.tabs-content {
  width: calc(100% - 7.88%);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.product-maindiv-score-v2 {
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
}

.composition-provisorio {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-start;
  gap: 3px;
}

.composition-provisorio-title {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #666666;
}

.composition-provisorio-map {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 1px;
}

.composition-provisorio-text {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #666666;
  text-align: end;
}

.product-maindiv-score-v2-desktop {
  width: 95%;
  height: fit-content;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.detailed-index-maindiv-score-v2-desktop {
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.title-product-maindiv-score-v2-desktop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #666666;
  text-decoration: underline;
  text-transform: uppercase;
}

.click-to-see-dpp-product {
  cursor: pointer;
}

.img-product-maindiv-score-v2-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  padding-top: 4px;
}

/* -------------------- */
.box-clicktosee {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1dvh 1dvw;
  box-shadow: 1px 1px 6px #00000050;
  border-radius: 5px;
  margin-top: 5px;
  position: relative;
  transition: transform 0.1s ease;
}

.box-clicktosee:hover {
  transform: scale(1.05);
}

.box-clicktosee-text {
  font-family: "Roboto";
  font-size: clamp(12px, 1.5dvw, 14px);
  text-align: center;
  color: #666666;
  text-transform: uppercase;
}

.box-clicktosee-img {
  margin-left: 0.6dvw;
}

.box-clicktosee-arrow {
  rotate: 180deg;
  margin-left: 1.2dvw;
}

.box-clicktosee-gif {
  position: absolute;
  bottom: -15px;
  width: 25px;
  height: 25px;
}
/* -------------------- */


.box-score-container-v2-desktop {
  background-color: white;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 97%;
  padding: 2dvw 0;
  gap: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  border-radius: 10px;
  z-index: 11;
}

.box-score-container-v2-desktop.without-score {
  background-color: white;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 97%;
  padding: 1.6dvw 0;
  gap: 10px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.0);
  backdrop-filter: blur(0px);
  border-radius: 10px;
  z-index: 11;
}

.overlay-desktop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10;
}

.icon-arrows-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-arrows {
  filter: brightness(50%);
  width: 14px;
  height: auto;
  padding-bottom: 8px;
}

.icon-arrows-desktop {
  filter: brightness(50%);
  width: 18px;
  height: auto;
  padding-bottom: 8px;
  transform: scale(-1);
}

/* ---------------------------- */

.product-maindiv-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.div-score-sac-ses-v2 {
  width: 92%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
  opacity: 0;
  animation: contentAppear 200ms ease-in forwards;
}

.sac-ses-new {
  width: calc(100% - 25px);
  display: flex;
  scale: 0.9;
}

.sac-ses-new-desktop {
  width: calc(100% - clamp(30px, 2.8dvw, 40px));
  display: flex;
  align-items: center;
  justify-content: end;
  scale: 0.9;
}

@keyframes contentAppear {
  to {
    opacity: 1;
  }
}

/* ----------------------------- */

/* div do journey - grelha */
.div-journey-v2 {
  width: 92%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

.div-journey-v2-desktop {
  width: 94%;
  height: 100%;
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

.div-detailed-index-v2-desktop {
  width: 94%;
  height: 100%;
  max-height: 70dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

.div-detailed-index-v2-page-2-desktop {
  width: 100%;
  height: 100%;
  max-height: 70dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: 0;
  animation: contentAppear 0.2s ease-out forwards;
}

@keyframes contentAppear {
  to {
    opacity: 1;
  }
}

/* ----------------------------- */

.product-maindiv-page4-v2 {
  width: 94%;
  height: calc(100% - 85px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding-top: 10px;
}

.product-maindiv-page4-v2.desktop {
  width: 94%;
  height: calc(100% - 20px);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  padding-top: 10px;
}

.product-div-journey {
  height: 100%;
}

@media screen and (min-height: 840px) {
  .product-div-journey {
    padding: 10px 0;
  }
}

/* ----------------------------- */

.page5-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 70px);
  align-items: center;
  justify-content: start;
  padding-top: 10px;
}

.slider-maindiv-page5 {
  width: 100%;
}

.product-maindiv-page5-v2-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  justify-content: space-evenly;
  animation: growWidth 0.5s forwards;
}

@keyframes growWidth {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.product-maindiv-page5-v2 {
  width: 100%;
  height: calc(100% - 10dvh);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
  margin-top: 4dvh;
  touch-action: auto;
}

.product-maindiv-page1-v2-desktop {
  width: 95%;
  display: flex;
  /*  height: 400px; */
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  min-height: 300px;
}

.container-arrows-content-desktop-product {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  user-select: none !important;
}

.properties {
  width: 94%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: auto; */
  padding-bottom: 10px;
}

.care-instructions-products {
  width: 94%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: auto; */
  padding-bottom: 10px;
}

.maintenance-discard {
  width: 94%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: auto; */
  padding-bottom: 10px;
}

.bill-of-materials {
  width: 94%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: auto; */
  padding-bottom: 10px;
}

.content-header-desktop {
  width: 92%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
}

.icon-arrow-back-desktop-pages {
  width: clamp(12px, 1.5dvw, 15px);
  height: clamp(12px, 1.5dvw, 15px);
  cursor: pointer;
}

.header-desktop-content-text {
  width: calc(100% - 30px);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: clamp(14px, 1.5dvw, 18px);
  color: #40454b;
}

.composition-new {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.composition-new-circular {
  width: fit-content;
}

.composition-new-bar {
  width: 100%;
}

@media screen and (min-width: 769px) {
  .arrow-left-product-img {
    height: 20px;
    width: 20px;
  }

  .arrow-left-product {
    display: flex;
    align-items: center;
  }

  .arrow-right-product-img {
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
  }

  .arrow-right-product {
    display: flex;
    align-items: center;

  }

  .properties {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .care-instructions-products {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 15px;
  }

  .maintenance-discard {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 5px;
    justify-content: center;
    width: 100%;
  }

  .longtext-container {
    width: 100%;
  }

  .bill-of-materials {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-bottom: 25px;
  }

  .composition-new-container {
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }

  .composition-new {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;
  }
  
  .composition-new-circular {
    width: fit-content;
  }
  
  .composition-new-bar {
    width: 100%;
  }
}

.product-rightside-main-div::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #f1f1f1;
}

::-webkit-scrollbar:vertical {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* @media screen and (min-width: 769px) {
body { calc(100dvh+ 200px) }
;

} */

.scroll-bar-pages {
  height: calc(100% - 16px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow-under-scrollbar-container {
  margin-top: 1.2dvh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5dvw;
}

.arrow-under-scrollbar {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  width: clamp(12px, 1.4dvw, 20px);
  height: clamp(12px, 1.4dvw, 20px);
  padding: clamp(2px, 0.1dvw, 3px);
  cursor: pointer;
  opacity: 0.8;
}

.arrow-under-scrollbar.down {
  rotate: 180deg;
}

.arrow-under-scrollbar.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.scroll-bar-pages.footer {
  width: 2px;
  height: calc(100% - 16px - clamp(80px, 17dvh, 1px));
  background: #f1f1f1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-bar-pages-item {
  width: 2px;
  height: calc(100% - 16px);
  background-color: #cbcbcb;
  border-radius: 5px;
}

.scroll-bar-pages-item.active {
  background: #555;
  border-radius: 5px;
}

/* TABSSSS */

.tabs-container {
  width: 92.12%;
  height: 30%;
  margin-top: 7px;
}

.tabs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 20px;
  position: relative;
}

.tabs-content {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  align-items: center;
  background-color: #fff;
}

.tab-button {
  text-align: center;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 12px;
  font-family: "Roboto";
  font-weight: 400;
  color: #666666;
  border-bottom: 1px solid #66666640;
  position: relative;
}

.tab-button.active {
  color: #666666;
  font-weight: 500;
}


.tabs-underline {
  margin-top: -1px;
  margin-left: 3.94%;
  position: absolute;
  height: 1px;
  width: 30.706%;
  background-color: #666666;
  transition: left 0.3s ease;
}

.tabs-underline.without-score {
  width: 46.06%;
}