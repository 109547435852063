.scale-vertical {
    width: fit-content;
    height: 17dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: none;
}

.letter-vertical {
    width: 45px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: bold;
    color: white;
}

.letter-vertical:nth-child(1) {
    background-color: #59c0e9;
    border-radius: 5px 5px 0 0;
    margin-bottom: 1px;
}
.letter-vertical:nth-child(2) {
    background-color: #00a2c9;
    margin-bottom: 1px;
}
.letter-vertical:nth-child(3) {
    background-color: #00a3b2;
    margin-bottom: 1px;
}
.letter-vertical:nth-child(4) {
    background-color: #006e8f;
    margin-bottom: 1px;
}
.letter-vertical:nth-child(5) {
    background-color: #002a69;
    margin-bottom: 1px;
}
.letter-vertical:nth-child(6) {
    background-color: #001c4a;
    border-radius: 0 0 5px 5px;
}

/* Estilo para a letra ativa */
.letter-vertical.active {
    border-radius: 10px;
    border: 5px solid white;
    margin-top: -2px;
    margin-bottom: -2px; 
    z-index: 2;
    font-size: 40px;
    width: 60px;
}

/* Media Queries para responsividade */
@media (min-width: 1024px) {
        .letter-vertical {
        width: 45px;
        font-size: 32px;
    }
        .letter-vertical.active {
        border-radius: 30px;
        width: 64px;
        font-size: 38px;
        border: 7px solid white; 
    }
}




/* Tablets (768px - 1024px) */
@media (max-width: 1024px) {
        .letter-vertical {
        width: 45px;
        font-size: 32px;
    }
        .letter-vertical.active {
        border-radius: 24px;
        width: 54px;
        font-size: 34px;
        border: 6px solid white; 
    }
}

@media (max-width: 768px) {
        .letter-vertical {
        width: 20px;
        font-size: 1.73dvh;
        margin-bottom: 0.2px;
    }
        .letter-vertical.active {
        border-radius: 10px;
        width: 24px;
        font-size: 2.4dvh;
        border: 2px solid white;
        line-height: 3.7dvh;
    }
}

@media (max-width: 300px) or (max-height: 680px) {
    .scale-vertical {
        height: 16dvh;
    }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
    .letter-vertical {
    width: 16px;
    font-size: 10px;
    margin-bottom: 0px;
}
    .letter-vertical.active {
    border-radius: 7px;
    width: 20px;
    font-size: 15px;
    border: 2px solid white;
    line-height: 22px;
}
}
