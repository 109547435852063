.journey-map-container {
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  animation: contentAppear 0.3s ease-out forwards;
}

@keyframes contentAppear {
  to {
      opacity: 1;
  }
}

.vertical-line {
  position: absolute;
  left: 15px;
  top: 0;
  width: 1px;
  background-color: #666666;
  height: calc(100% - 30px);
  z-index: 0;
}

.journey-map {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 999;
}

.journey-map-step {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.icon-step {
  background-color: white;
  width: 30px;
  height: 30px;
}

.step-show-details {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.journey-map-step-title-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.journey-map-step-title {
  font-size: 13px;
  font-weight: normal;
  color: #666666;
  line-height: 100%;
  cursor: pointer;
}

.journey-map-step-title.expanded {
  font-weight: bold;
  text-decoration: underline;
}

.journey-map-step-plus {
  font-size: 16px;
  color: #666666;
  cursor: pointer;
  margin-left: 5px;
  display: inline-block;
  user-select: none;
}

.journey-map-step-plus .plus-sign {
  display: inline-block;
  transition: transform 0.6s ease;
}

.journey-map-step-plus.expanded .plus-sign {
  transform: rotate(-45deg);
}

.plus-sign {
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.33);
}

.step-details {
  margin-left: 10px;
  font-size: 12px;
  color: #666666;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.journey-expanded-separator {
  display: flex;
  flex-direction: row;
  gap: 7px;
}

.separator-journey-container {
  width: 1px;
  display: flex;
  justify-content: end;
}

.separator-journey {
  width: 1px;
  height: 100%;
  background-color: #6666661e;
}

.step-details.expanded {
  max-height: 1000px;
  opacity: 1;
}

.step-text-company {
  font-size: 12px;
  font-weight: bold;
  color: #0099CC;
}

.step-text-country {
  font-size: 10px;
  font-weight: normal;
  color: #40454B;
}

.fibers-container {
  margin-top: 5px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fiber-item {
  display: flex;
  flex-direction: column;
}

.fiber-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fiber-title {
  font-size: 12px;
  font-weight: bold;
  color: #666666;
  line-height: 100%;
  cursor: pointer;
}

.fiber-title.expanded {
  text-decoration: underline;
}

.fiber-details {
  margin-left: 10px;
  font-size: 12px;
  color: #666666;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease;
  opacity: 0;
}

.fiber-details.expanded {
  display: flex;
  flex-direction: row;
  gap: 7px;
  max-height: 1000px;
  opacity: 1;
}


@media screen and (min-height: 840px) {
  .icon-step {
    width: 35px;
    height: 35px;
  }

  .journey-map-step-title {
    font-size: 14px;
  }

  .journey-map-step-plus {
    font-size: 17px;
  }

  .step-show-details {
    padding-top: 7px;
  }
}

@media screen and (min-width: 769px) {
  .icon-step {
    width: clamp(30px, 2.8dvw, 40px);
    height: clamp(30px, 2.8dvw, 40px);
  }

  .fiber-details.expanded {
    display: flex;
    flex-direction: row;
    gap: 7px;
    max-height: 1000px;
    opacity: 1;
  }

  .journey-expanded-separator {
    display: flex;
    flex-direction: row;
    gap: 7px;
  }

  .journey-expanded-column {
    display: flex;
    flex-direction: column;
  }

  .separator-journey-container {
    width: 1px;
    display: flex;
    justify-content: end;
  }

  .separator-journey {
    width: 1px;
    height: 100%;
    background-color: #6666661e;
  }
  
  .vertical-line {
    position: absolute;
    left: clamp(15px, 1.4dvw, 20px);
    top: 0;
    width: 1px;
    background-color: #666666;
    height: calc(100% - 30px);
    z-index: 0;
  }

  .journey-map-step-title  {
    font-size: clamp(12px, 1dvw ,18px);
    font-family: "Roboto";
    font-weight: normal;
    color: #666666;
    text-underline-offset: 3px;
  }

  .fibers-container {
    margin-top: 3px;
    gap: 5px;
  }

  .fiber-title {
    font-size: clamp(11px, 1dvw ,14px);
    font-family: "Roboto";
    font-weight: bold;
    color: #666666;
  }

  .step-show-details {
    padding-top: clamp(6px, 0.7dvw, 10px);
    gap: 5px;
  }

  .step-text-company {
    font-size: clamp(8px, 1dvw ,13px);
    font-weight: bold;
    color: #0099CC;
  }
  
  .step-text-country {
    font-size: clamp(8px, 0.96dvw ,13px);
    font-weight: normal;
    color: #40454B;
  }
}

@media (min-width: 769px) and (max-height: 640px) {
  .icon-step {
    width: 28px;
    height: 28px;
  }
  
  .vertical-line {
    position: absolute;
    left: 14px;
    top: 0;
    width: 1px;
    background-color: #666666;
    height: calc(100% - 4.44dvh);
    z-index: 0;
  }

  .journey-map-step-title  {
    font-size: 13px;
    font-family: "Roboto";
    font-weight: normal;
    color: #666666;
  }

  .fibers-container {
    margin-top: 3px;
    gap: 3px;
  }

  .fiber-title {
    font-size: 11px;
    font-family: "Roboto";
    font-weight: bold;
    color: #666666;
  }

  .step-show-details {
    padding-top: 3px;
  }

  .step-text-company {
    font-size: 10.5px;
    font-weight: bold;
    color: #0099CC;
  }
  
  .step-text-country {
    font-size:10.5px;
    font-weight: normal;
    color: #40454B;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  
  .vertical-line {
    left: 13px;
    height: calc(100% - 25px);
  }
  
  .icon-step {
    width: 26px;
    height: 26px;
  }
  
  .step-show-details {
    padding-top: 3px;
  }
  
  .step-text-company {
    font-size: 10px;
  }
  
  .step-text-country {
    font-size: 10px;
  }
  
  .fibers-container {
    margin-top: 0px;
    margin-left: 5px;
    gap: 2px;
  }
  
  .fiber-item {
    display: flex;
    flex-direction: column;
  }
  
  .fiber-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .fiber-title {
    font-size: 11px;
    font-weight: bold;
    color: #666666;
    line-height: 100%;
    cursor: pointer;
  }
  
  .fiber-details {
    margin-left: 10px;
    font-size: 12px;
    color: #666666;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease;
    opacity: 0;
  }

  .journey-map-step-plus  {
    display: flex;
    align-items: center;
  }

  .plus-sign {
    font-size: 11px;
  }
}
