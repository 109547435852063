.notfound-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    justify-content: flex-start;
}

.notfound-header {
    display: flex;
    width: 98%;
}

.notfound-error404 {
    height: 100%;
    display: flex;
    align-items: center;
}

.notfound-footer {
    display: flex;
    align-items: center;
}