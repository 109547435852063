.productmain-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5px;
}

.productmain-img {
    z-index: 999;
    border-radius: 5px;
    width: 100%;
    height: 68.4dvh;
    object-fit: cover;
    background-color: #f0f2f5;
}

.image-fade {
    background-color: white;
}

.image-fade-enter {
    z-index: 999;
    opacity: 0;
    object-fit: cover;
    transform: translate(-13dvh, -27dvh) scale(0.4, 0.2);
  }
  
  .image-fade-enter-active {
    z-index: 999;
    opacity: 1;
    transform: scale(1); /* Scale up to 1 */
    transition: opacity 100ms, transform 200ms ease-out;
  }
  
  .image-fade-exit {
    opacity: 1;
  }
  

@media screen and (max-height: 710px) {
    .productmain-img {
        height: 62dvh;
    }
}

.productmain-texts {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.productmain-name {
    color: #40454B;
    font-size: 15px;
    font-variant: small-caps;
}

.productmain-brand {
    color: #666;
    font-size: 13px;
    font-variant: small-caps;
}
