.scale-v2 {
  display: flex;
  align-items: center;
  height: 100%;
  width: fit-content;
}

.letter {
  width: 50px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-size: 28px;
  font-weight: bold;
  color: white;
}

/* Estilos específicos para cada letra */
.letter:nth-child(1) {
  background-color: #59c0e9;
  border-radius: 7px 0px 0px 7px;
  margin-right: 1px;
}
.letter:nth-child(2) {
  background-color: #00a2c9;
  margin-right: 1px;
}
.letter:nth-child(3) {
  background-color: #00a3b2;
  margin-right: 1px;
}
.letter:nth-child(4) {
  background-color: #006e8f;
  margin-right: 1px;
}
.letter:nth-child(5) {
  background-color: #002a69;
  margin-right: 1px;
}
.letter:nth-child(6) {
  background-color: #001c4a;
  border-radius: 0px 7px 7px 0px;
}

/* Estilo para a letra ativa */
.letter.active {
  border-radius: 10px;
  border: 3px solid white;
  margin-right: -3px;
  margin-left: -3px;
  z-index: 2;
  font-size: 28px;
  width: 65px;
}

@media screen and (min-width: 1280px) {
  .scale-v2 {
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
  }

  .letter {
    width: clamp(18.81px, 1.9dvw, 29.07px);
    /*   width: clamp( 22px, 1.9dvw, 34px); */
    height: clamp(24px, 2dvw, 32px);
    font-size: clamp(13.3px, 1.2dvw, 19px);
  }

  .letter.active {
    /*border-radius: 10px */
    border-radius: 13px;
    width: clamp(21.85px, 2dvw, 30.78px);
    /* width: clamp( 24px, 2dvw, 36px); */
    height: clamp(31px, 2.7dvw, 41px);
    font-size: clamp(16.2px, 1.75dvw, 21.06px);;
    /* font-size: clamp( 20px, 1.75dvw, 26px); */
    border: 1.7px solid white;
  }
}

@media screen and (min-width: 769px) and (max-width: 1280px) {
    .scale-v2 {
      display: flex;
      align-items: center;
      height: 100%;
      width: fit-content;
    }
  
    .letter {
      width: clamp(18.81px, 1.9dvw, 29.07px);
      /*   width: clamp( 22px, 1.9dvw, 34px); */
      height: clamp(24px, 2dvw, 32px);
      font-size: clamp(13.3px, 1.2dvw, 19px);
    }
  
    .letter.active {
      /*border-radius: 10px */
      border-radius: 10px;
      width: clamp(21.85px, 2dvw, 30.78px);
      /* width: clamp( 24px, 2dvw, 36px); */
      height: clamp(30px, 2.6dvw, 40px);
      font-size: clamp(16.2px, 1.75dvw, 21.06px);
      /* font-size: clamp( 20px, 1.75dvw, 26px); */
      border: 1.7px solid white;
    }
  }

/* Smartphones (até 767px) */
@media (max-width: 767px) {
  .letter {
    width: 32px; /* 40px * 0.8 */
    height: 36px; /* 45px * 0.8 */
    font-size: 18px; /* 20px * 0.8 */
  }
  .letter.active {
    border-radius: 10px; /* 20px * 0.8 */
    width: 36px; /* 45px * 0.8 */
    height: 48px; /* 60px * 0.8 */
    font-size: 24px; /* 30px * 0.8 */
    border: 1.6px solid white; /* 2px * 0.8 */
  }
}

/* Tamanhos menores de smartphones (até 480px) */
@media (max-width: 480px) {
  .letter {
    width: 28px;
    height: 35px;
    font-size: 17px;
  }
  .letter.active {
    border-radius: 10px;
    width: 35px;
    height: 45px;
    font-size: 25px;
    border: 1px solid white;
  }
}


@media (max-width: 300px) or (max-height: 740px) {
  .letter {
    width: 25px;
    height: 30px;
    font-size: 17px;
  }
  .letter.active {
    border-radius: 10px;
    width: 32px;
    height: 42px;
    font-size: 25px;
    border: 1px solid white;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .letter {
    width: 23px;
    height: 24px;
    font-size: 14px;
  }
  .letter.active {
    border-radius: 10px;
    width: 28px;
    height: 34px;
    font-size: 22px;
    border: 1px solid white;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .letter {
    width: 23px;
    height: 24px;
    font-size: 14px;
  }
  .letter.active {
    border-radius: 10px;
    width: 28px;
    height: 34px;
    font-size: 22px;
    border: 1px solid white;
  }
}

@media (max-width: 300px) or (max-height: 680px) {
  .score-container {
    margin-top: 5px;
  }

  .letter {
      width: 23px;
      height: 25px;
      font-size: 16px;
  }
  .letter.active {
      border-radius: 10px;
      width: 29px;
      height: 34px;
      font-size: 23px;
      border: 1px solid white;
  }
}