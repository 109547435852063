.score-description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3px;
}

.score-description-title {
    color: #40454B;
    font-size: 13px;
    margin-top: 5px;
    font-weight: bold;
    font-variant: small-caps;
}

.score-description-text {
    color: #40454B;
    text-align: center;
    font-size: 11px;
    line-height: 1em;
    font-variant: small-caps;
}