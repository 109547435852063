.detailedindex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 10px;
}

.detailedindex-img {
    height: 60px;
    filter:contrast(30%);
}

.detailed-index-texts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detailedindex-description {
    width: 100%;
    color: #666;
    font-size: 13px;
    font-variant: small-caps;
    text-align: center;
    line-height: 13px;
    height: 26px;
}

.detailedindex-value-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
}

.detailedindex-value-value {
    color: #113678;
    font-size: 20px;
    font-weight: bold;
    font-variant: small-caps;
    text-align: center;
}

.detailedindex-value-unit {
    color: #113678;
    font-size: 19px;
    font-weight: bold;
    font-variant: small-caps;
    text-align: center;
}

@media screen and (max-height: 740px) {
    .detailedindex-container {
        gap: 0.5em;
    }
    
    .detailedindex-img {
        height: 35px;
    }

    .detailedindex-value-value {
        font-size: 17px;
    }
    
    .detailedindex-value-unit {
        font-size: 17px;
    }
}

@media screen and (max-height: 640px) {
    .detailedindex-container {
        gap: 0.5em;
    }
    
    .detailedindex-img {
        height: 30px;
    }

    .detailedindex-value-value {
        font-size: 16px;
    }
    
    .detailedindex-value-unit {
        font-size: 16px;
    }

    .detailedindex-description {
        font-size: 12px;
    }
}

@media screen and (max-width: 320px) {
    .detailedindex-description {
        font-size: 12px;
    }
}
