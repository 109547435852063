.additionalinfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;
    height: 88px;
}

.additionalinfo-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2em;
}

.additionalinfo-title {
    color: #40454B;
    font-size: 15px;
    font-weight: bold;
    font-variant: small-caps;
}

.additionalinfo-title-info {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
}

.additionalinfo-text {
    display: flex;
    align-items: center;
    margin-top: -10px;
    color: #40454B;
    text-align: center;
    font-size: 12px;
    font-variant: small-caps;
    width: 90%;
    height: 100%;
}

.additionalinfo-scorescale {
    display: flex;
    margin-top: -10px;
}