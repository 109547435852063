body {
    font-family: 'Roboto', sans-serif;
}

table thead tr {
    font-weight: normal !important; 
}

.tabela-container {
    display: flex;
    justify-content: center;
    height: fit-content;
}

.tabela {
    table-layout: fixed;
    width: 100%;
    margin-top: 20px;
}

.tabela th, .tabela td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.tabela th {
    text-align: center;
    border-top: none; 
    border-left: none;
}

.tabela tr {
    font-weight: normal;
}

.tabela td {
    text-align: center;
    border-top: none; 
    border-left: none;
    vertical-align: middle;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
   /*  line-height: 14px; */
    text-transform: capitalize;
    color: #666666;
    height: 20px;
}

.tabela td.type-bom img,
.tabela td.type-bom-last img {
    margin-right: 5px; 
    width: 20px;
}

.tabela td.type-bom {
    text-align: left;
    display: flex;
    align-items: center;
}

.tabela td.material {
    border-right: none;
}

.tabela tr.tabela-heads {
    font-size: 13px;
    line-height: 14px;
    text-transform: capitalize;
    color: #666666;
}

.tabela th.head-material {
    border-right: none;
}

.tabela td.material-last {
    border-bottom: none;
    border-right: none;
}

.tabela td.type-bom-last {
    text-align: left;
    border-bottom: none;
    display: flex;
    align-items: center;
}

.tabela td.quantity-last {
    border-bottom: none;
}

.acessory-bom-icon {
    width: 15px;
    height: 15px;
}

/* Media queries para dispositivos móveis */
@media only screen and (max-width: 480px) {
    .tabela-container {
        width: 100%;
        margin: 0 auto;
        overflow-x: auto; 
    }

    .tabela {
        width: 85%; 
        margin-top: 10px;
    }

    .tabela th,
    .tabela td {
        padding: 5px; 
        font-size: 13px;
       /*  height: 17px; */
    }

    .tabela td.type-bom img,
    .tabela td.type-bom-last img {
        margin-right: 4px;
        width: 12px; 
        height: 12px;
        /* padding-top: 1px; */
    }

    .span-bom-text {
        display:flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
    }
}

/* Media queries para dispositivos móveis */
@media only screen and (min-width: 480px) and (max-width: 768px) {
    .tabela-container {
        width: 100%; 
        margin: 0 auto; 
        overflow: auto;
    }

    .tabela td.type-bom img,
    .tabela td.type-bom-last img {
        margin-right: 8px; 
        width: 15px;
    }

    .tabela {
        width: 87%;
        margin-top: 20px;
    }

    .tabela th,
    .tabela td {
        padding: 6px;
        height: 15px;
    }

    .tabela tr.tabela-heads {
        font-size: 14px;
    }

    .tabela td {
        font-size: 14px;
    }
}

@media only screen and (min-width: 768px) {
    .tabela-container {
        width: 100%; 
        margin: 0 auto;
    }

    .tabela td.type-bom img,
    .tabela td.type-bom-last img {
        margin-right: clamp(2px,0.26dvw, 5px); 
        width: 15px;
    }

    .tabela {
        max-height: 500px;
        width: 85%;
        margin-top: 0px;
    }

    .tabela tr.tabela-heads {
        font-size: clamp(11px, 1.3dvw, 14px);
    }

    .tabela td {
        font-size: clamp(11px, 1.3dvw, 14px);
       /*  padding:5px; */
    }

    .tabela th,
    .tabela td {
        padding: 8px; 
    }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
    .tabela {
        width: 85%; 
    }

    .tabela th,
    .tabela td {
        padding: 2px; 
        font-size: 12px;
    }

    .span-bom-text {
        font-size: 11px;
    }
}
