.panel-filters {
  position: fixed;
  left: 0;
  right: 0;
  height: auto;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.panel-filters.open {
  height: auto;
  z-index: 1000;
}

.container-filters-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.container-filters {
  background-color: #F0F0F0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 800;
  justify-self: center;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.arrow-filters {
  cursor: pointer;
}

/* ----------- SCORE SCALE ------------ */

.score {
  margin-bottom: 4px;
  background-color: white;
  box-shadow: 0px 1px 1px 1px #00000020;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 3px;
}

.score-content {
  display: flex;
  width: calc(100% - 15px);
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}

.score-text-filters {
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 600;
  color: #40454B;
}

.scale-v2 {
  transform: scale(0.9);
  margin-left: -7px;
}

.letter {
  margin-top: 6px;
  margin-bottom: 6px;
}

.letter.active {
  scale: 0.9;
  margin-top: 0px;
  margin-bottom: 0px;
}


@media screen and (min-width: 769px) {
  .scale-v2 {
    scale: 1;
    margin-left: 0;
  }
  
  .letter.active {
    scale: 1;
  }
}

/* -----------   BRAND   -------------- */

.brand {
  margin-bottom: 4px;
  background-color: white;
  box-shadow: 0px 1px 1px 1px #00000020;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.brand-content {
  display: flex;
  width: calc(100% - 15px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.brand-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.brand-search-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
}

.brand-search-input {
  width: 35%;
  border: none;
  border-bottom: 1px solid #40454B;
  border-radius: 0;
  outline: none;
  color: #40454b;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
}

.brand-search-img {
  width: 15px;
  height: 15px;
}

.brand-scroll-container {
  width: 100%;
  display: flex;
  overflow-x: auto;
  gap: 5px;
  white-space: nowrap;
  padding: 0px;
}

.brand-scroll-container.full {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  padding: 0px;
  flex-wrap: wrap;
}

.brand-scroll-container::-webkit-scrollbar {
  display: none;
}

.brand-item {
  background: #EFEFEF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
}

.brand-item.selected {
  background-color: #0099B0;
  color: white;
}

/* --------------   TYPE  ----------------- */

.type {
  margin-bottom: 4px;
  background-color: white;
  box-shadow: 0px 1px 1px 1px #00000020;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.type-content {
  display: flex;
  width: calc(100% - 15px);
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 10px 0;
}

.type-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.type-scroll-container {
  width: 100%;
  display: flex;
  overflow-x: auto;
  gap: 5px;
  white-space: nowrap;
  padding: 0px;
}

.type-scroll-container.full {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  white-space: nowrap;
  padding: 0px;
  flex-wrap: wrap;
}

.type-scroll-container::-webkit-scrollbar {
  display: none;
}

.type-item {
  background: #EFEFEF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-family: "Roboto";
  font-size: 11px;
  font-weight: 400;
}

.type-item.selected {
  background-color: #0099B0;
  color: white;
}



/* ---------------------------------------- */

.bottom-filters {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Roboto";
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 2px;
  cursor: pointer;
}

.apply-filters {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;
  text-align: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: center;
  color: #0099cc;
  cursor: pointer;
  box-shadow: 1.16px 1.16px 4.66px 0px #0000001a inset;
}

.clear-filters {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 50%;
  text-align: center;
  text-align: center;
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: center;
  color: #b2b2b2;
  border-radius: 0px 0px 5px 0px;
  cursor: pointer;
  box-shadow: 1.16px 1.16px 4.66px 0px #0000001a inset;
}

.apply-filters::before,
.apply-filters::after {
  content: "";
  position: absolute;
}

.apply-filters::before {
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
}

.apply-filters::after {
  top: 0;
  right: 0;
  bottom: 0;
  width: 1px;
}



.panel-filters-desktop {
  top: 20px;
  right: 0;
  position: absolute;
  height: auto;
  border-left: 1px solid #00000030;
  border-right: 1px solid #00000030;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: 0px 4.66px 4.66px 0px #00000040;
  z-index: 1001;
}

.container-filters-desktop {
  background-color: #F0F0F0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30dvw;
  z-index: 800;
  gap: 2px;
  justify-self: center;
}
