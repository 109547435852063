.error404-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
}

.error404-title {
    color: #666;
    font-size: 30px;
    font-weight: bold;
    font-variant: small-caps;
}

.error404-img {
    width: 200px;
    height: 200px;
}

.error404-text {
    color: #666;
    text-align: center;
    font-size: 15px;
    font-variant: small-caps;
}