.bar-chart-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.bar-chart-composition-label {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 4px;
  font-size: clamp(9px, 1.4dvh, 12px);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.bar-chart-composition-value {
  font-size: clamp(10px, 1.55dvh, 13px);
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}

.bar-wrapper {
  background-color: #66666610;
  height: clamp(3px, 0.4dvh, 5px);
  overflow: hidden;
  border-radius: 0 5px 5px 0;
}

.bar {
  height: 100%;
  border-radius: 0 5px 5px 0;
  background-color: red;
}

@media screen and (max-height: 680px) {
  .bar-chart-container {
    gap: 2px;
  }

  .bar-container {
    gap: 1px;
  }
}

@media screen and (max-height: 650px) {
  .bar-chart-container {
    gap: 1px;
  }

  .bar-container {
    gap: 0px;
  }
}

@media screen and (min-width: 769px) {
  .bar-chart-container {
    width: 100%;
    gap: 10px;
  }
  
  .bar-container {
    gap: 2px;
  }
  
  .bar-chart-composition-label {
    gap: clamp(5px, 0.7dvw, 15px);
    font-size: clamp(11px, 1.3dvw, 14px);
  }
  
  .bar-chart-composition-value {
    font-size: clamp(14px, 1.8dvw, 19px);
    line-height: clamp(14px, 1.8dvw, 19px);
  }
  
  .bar-wrapper {
    background-color: #66666610;
    height: clamp(3px, 0.54dvh, 5px);
    overflow: hidden;
    border-radius: 0 5px 5px 0;
  }
  
  .bar {
    height: 100%;
    border-radius: 0 5px 5px 0;
    background-color: red;
  }
}
