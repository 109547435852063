.navbar-desktop {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  color: white;
  width: 100%;
  height: clamp( 45px, 8.5dvh,72px);
  left: 0;
  right: 0;
  top: 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.navbar-desktop.ishomepage {
  background: transparent;
  box-shadow: none;
  border-bottom: 2px solid #FFFFFF99;
}

.navbar-left-desktop,
.navbar-center-desktop {
  display: flex;
  align-items: center;
}

.navbar-right-desktop {
  display: flex;
  align-items: center;
}

.navbar-left-desktop.ishomepage,
.navbar-center-desktop.ishomepage,
.navbar-right-desktop.ishomepage {
  filter: invert(100%) brightness(100);
}

.navbar-item-desktop-language {
  display: flex;
  align-self: center;
}

.navbar-right-desktop {
  flex: 6;
  justify-content: flex-end;
}

.navbar-left-desktop {
  flex: 6;
  justify-content: flex-start;
}
.navbar-center-desktop {
  flex: 2;
  justify-content: center;
}

.separator-navbar-line {
  filter: blur(0.9px);
  background: linear-gradient(
    180deg,
    rgba(99, 99, 99, 0) 0%,
    #525252 50%,
    rgba(102, 102, 102, 0) 100%
  );
  opacity: 0.3;
  height: 80%;
  width: 1px;
}

.navbar-item-desktop {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: clamp( 13px, 2.3dvh,15px);
  line-height: 18px;
  display: flex;
  flex-direction: column; /* Organiza os itens em coluna */
  align-items: center; /* Centraliza os itens horizontalmente */
  text-align: center;
  color: #40454b;
  text-decoration: none;
  padding: 0 15px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.text-underline-navbardesktop {
  margin-top: 1px;
  background: linear-gradient(
    180deg,
    rgba(99, 99, 99, 0) 0%,
    #525252 50%,
    rgba(102, 102, 102, 0) 100%
  );
  height: 2px;
  width: 75%;
  background: #40454b;
}

.navbar-item-center-desktop {
  display: flex;
  flex-direction: column; /* Organiza os itens em coluna */
  align-items: center; /* Centraliza os itens horizontalmente */
  text-align: center;
  color: #666666;
  text-decoration: none;
  padding: 0 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.main-icon-navbar-desktop {
  width: auto;
  height: clamp( 35px, 6.5dvh,52px);
}

.item-desktop-search {
  margin-top: auto;
  margin-bottom: auto;
}

.icon-search-desktop {
  height: 15px;
  width: 15px;
}

.icon-search-desktop.active {
  height: 15px;
  width: 15px;
}

.icon-search-dot-selected {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dot-desktop-search {
/*   display: flex;
  flex-direction: column; */
  font-size: 10px;
}

.item-desktop-search {
  font-size: 3px;
}

.icon-search-desktop.active::after {
  content: ".";
  position: absolute;
  bottom: 23.5px;
  color: #40454b;
  font-size: 1.4em;
  font-weight: bold;
  font-family: "Roboto";
} 

.navbar-item-desktop-search {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10.4962px;
  line-height: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #666666;
  text-decoration: none;
  padding: 0 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}

  .icon-search-dot-selected.active::after {
  content: ".";
  position: absolute;
  top: 48%;
  transform: translateX(110%);
  color: #40454b;
  font-size: 1.4em;
  font-weight: bold;
}  
