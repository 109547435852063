.searchbox-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  padding-top: 5px;
}

.searchbox-v2-input-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 195px;
  width: 100%;
  height: 100%;
  border: solid 2px #f7f7f7;
  border-radius: 14px;
  background-color: #f7f7f7;
  padding: 5px 8px;
  gap: 8px;
  animation: moveFromRightComponent 0.3s ease-out;
  position: relative;
  z-index: 0;
}

.searchbox-v2-input-component.active {
  border: none;
  border-radius: 14px;
  background-color: #ffffff;
}

.searchbox-v2-input-component-desktop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: clamp(260px, 22dvw, 400px);
  min-height: 25px;
  height: 100%;
  border: solid 2px #f7f7f7;
  border-radius: 40px;
  background-color: #f7f7f7;
  padding: 3px 8px;
  gap: 8px;
  animation: moveFromRightComponent 0.3s ease-out;
  position: relative;
  z-index: 0;
}

@keyframes moveFromRightComponent {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.icon-searchboxinput-v2 {
  width: 15px;
  height: 15px;
}

.icon-searchboxinput-v2.active {
  display: none;
}

.searchboxinput-value-v2 {
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  color: #a1a1a1;
  font-size: 14px;
/*   font-weight: bold; */
  width: 100%;
}

.searchboxinput-value-v2.active {
  opacity: 0;
}

.searchboxinput-value-v2::placeholder {
  background-color: transparent;
  color: #a1a1a1;
  font-weight: normal;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.icon-searchboxinput-qr-v2 {
  width: 15px;
  height: 15px;
}

.searchboxinput-value-v2-desktop::placeholder {
  background-color: transparent;
  color: #a1a1a1;
  font-weight: normal;
  font-size: clamp(12px, 1.1dvw, 16px);
  font-style: normal;
  line-height: 28px;
  text-transform: capitalize;
  padding-top: 10px;
}

.searchboxinput-value-v2-desktop {
  background-color: transparent;
  height: 100%;
  border: none;
  outline: none;
  color: #a1a1a1;
  font-size: clamp(14px, 1.5dvw, 18px);
/*   font-weight: bold; */
  width: 100%;
  padding-top: 4.5px;
}

.icon-searchboxinput-v2-desktop {
  padding-top: 2px;
  width: 18px;
  height: 18px;
}

@media (min-width: 769px) and (max-height: 1400px) {
  .searchbox-v2 {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
  }
}


@media (max-width: 769px) {
  .searchbox-full-width {
    width: 100%;
  }
}