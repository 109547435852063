.longtext-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    height: 29dvh;
    gap: 0.2em;
}

.longtext-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #40454B;
    font-size: 1.9dvh;
    font-weight: bold;
    font-variant: normal !important;
    font-family: 'Roboto' !important;
    line-height: 15px;

}

.longtext-text {
    width: 100%;
    height: 100%;
    overflow: hidden;
    color: #40454B;
    font-size: 1.6dvh;
    font-variant: normal !important;
    text-align: justify;
    font-family: 'Roboto';
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;

}

.longtext-text.centered-text {
    display: flex;
    justify-content: center;
}


.see-more-button {
    color: #747474;
    font-size: 13px;
    font-variant: small-caps;
    position: absolute;
    bottom: 0;
    border: none;
    width: 100%;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0.0) 0%, rgb(236, 236, 236) 70%);
    height: 7dvh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
  }
  
.see-more-button-eye-img {
    width: 20px;
}


/* Media queries para dispositivos móveis */
@media only screen and (max-width: 480px) {
    .longtext-text {
        font-size: 13px;
    }

    .longtext-title {
        font-size: 1.6dvh;
    }
}

/* Media queries para dispositivos móveis */
@media only screen and (min-width: 480px) and (max-width: 768px) {
    .longtext-text {
        font-size: 15px;
    }

    .longtext-title {
        font-size: 1.7dvh;
    }
}


@media only screen and (min-width: 768px) {
    .longtext-text {
        font-size:  clamp(11px, 1.3dvw, 14px);
       /*  font-size: clamp(13px, 2.1dvh, 18px); */
    }

    .longtext-title {
        font-size: 1.8dvh;
    }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
    .longtext-text {
        font-size: 12px;
    }
}
