.sponsor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
}

.sponsor-title {
    line-height: 17px;
    color: #40454B;
    font-size: 11px;
    font-weight: bold;
    font-variant: small-caps;
}

.sponsor-img {
    width: 90%;
    max-width: 310px;
}