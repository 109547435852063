
.container-redirect-component {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.8);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}

.container-redirect-component:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.7);
}

.redirect-v2-input-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 100%;
  padding: 5px 30px;
}

.redirect-button-v2 {
  display: flex;
  justify-content: center;
  background-color: transparent;
  z-index: 1;
  opacity: 1;
}

.text-redirect-button {
  font-family: "Roboto", sans-serif; 
  font-size: clamp(16px, 1.5dvw, 20px);
  line-height: 1.5;
  text-align: center;
  color: #ffffff; 
  position: relative;
  z-index: 2;
  opacity: 1;
}
