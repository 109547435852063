
.slide-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}


.tab-indicator-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 4px;
}

.tab-indicator-background {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e9e9e9;
    height: 1px;
}

.tab-indicator {
    background-color: #e9e9e9;
    width: 100%;
    border-radius: 5px;
    height: 1px;
}

.tab-indicator.active {
    background-color: rgba(64, 68, 74, 0.75);
    height: 2px;
}

.tabs-maxheight {
    height: 100%;
}

.react-swipeable-view-container {
    height: 100%;
}

.tab {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    height: 100%;
}

.tabs-container-swipeableviews {
    height: 100%;
}

.react-swipeable-view-container {
    height: 100%;
}
