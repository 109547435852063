.page-products-container {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 73px);
}

.page-products-container-desktop {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8dvh;
}

.title {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  text-transform: capitalize;
  color: #40454b;
}

.filters {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  color: #40454b;
  cursor: pointer;
}

.filters img {
  padding-top: 1.3px;
  padding-left: 10px;
  width: 13px;
  height: 13px;
}

.group-by {
  display: flex;
  flex-direction: row;
}

.icon {
  margin-right: 10px;
}

.icon.active::after {
  content: "-";
  position: absolute;
  bottom: -13px;
  transform: translateX(-160%);
  font-size: 1.5em;
}

.icon-second.active::after {
  content: "-";
  position: absolute;
  bottom: -13px;
  transform: translateX(-160%);
  font-size: 1.5em;
}

.filters-and-group-by {
  width: calc(100% - 15px);
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: 7.5px;
  z-index: 250;
}

.page-products-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  padding-top: 5px;
}

.page-product-items {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50%;
  row-gap: 0px;
  column-gap: 10px;
  padding: 5px 5px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
  min-height: 100px;
}

.page-product-items.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) and (max-height: 680px) {
  .page-product-items {
    row-gap: 7px;
  }
}

.page-product-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.footer-navbar {
  display: flex;
  bottom: 0;
}

.page-products-content-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 5px;
  overflow: hidden;
}

.page-product-items-inline {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 35%;
  row-gap: 10px;
  column-gap: 10px;
  padding: 10px 5px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
}

.page-product-item-inline {
  width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* --------- SEARCH BAR ------------ */

.searchbar-v2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}

.searchbar-v2-input-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 100%;
  border: solid 2px #d1d1d140;
  border-radius: 5px;
  background-color: white;
  padding: 5px 8px;
  gap: 8px;
  animation: moveFromRightComponent 0.3s ease-out;
}

@keyframes moveFromRightComponent {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.icon-searchinput-v2 {
  width: 15px;
  height: 15px;
}

.searchinput-value-v2 {
  height: 100%;
  border: none;
  outline: none;
  color: #40454b;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.searchinput-value-v2::placeholder {
  color: #40454ba6;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}

.icon-searchinput-qr-v2 {
  width: 15px;
  height: 15px;
}

.qr-reader {
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-reader button {
  padding: 10px 20px;
  background: #fff;
  border: none;
  cursor: pointer;
}

.filters-img-close {
  width: 6px;
  height: 6px;
}

.page-products-navbar {
  display: flex;
  z-index: 2000;
  /*   position: fixed; */
}

.filters-and-group-by-desktop {
  /*  position: relative; */
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 250;
}

.top-info-desktop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* position: relative; */
}

.filters-desktop {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  color: #40454b;
  cursor: pointer;
  align-items: center;
}

.filters-desktop img {
  padding-left: 10px;
  padding-bottom: 0.5px;
  width: 13px;
  height: 13px;
}

.navbar-footer-products {
  display: flex;
  /*   position: absolute; */
  /*   bottom: 0; */
  /* padding-top: 48px; */
  width: 100%;
}

.navbar-footer-products-search {
  position: absolute;
  bottom: calc(-8dvh - 80px);
  width: 100%;
}

.page-product-items-desktop {
  /*   height: calc(100dvh - 130px); */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 48%;
  row-gap: 17px;
  column-gap: 6px;
  padding: 0px 5px;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  box-sizing: border-box;
  min-height: 300px;
  padding-bottom: 0px;
}

.page-product-items-desktop.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-product-items-desktop.search.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-products-content-desktop {
  display: flex;
  height: calc(100dvh - calc(8dvh + clamp(45px, 8.5dvh, 72px)));
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: calc(8dvh + clamp(45px, 6.5dvh, 72px));
  padding-left: clamp(120px, 4.5vh + 30px, 110px);
  padding-right: clamp(120px, 4.5vh + 30px, 110px);
}

.container-desktop-page-products {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 1001;
}

.page-products-footer-content-desktop {
  display: flex;
  flex-direction: column;
  /*   margin-top: 75px; */
  height: 100dvh;
  overflow: hidden;
}

.page-products-container-search {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 73px);
}

.no-products-message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #40454b;
}

@media (min-width: 1150px) {
  .page-product-items-desktop {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 900px) and (max-width: 1150px) {
  .page-product-items-desktop {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding-top: 5px;
  }
  .page-product-items {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .page-product-items-desktop {
    grid-template-columns: repeat(4, 1fr);
    padding-top: 5px;
  }

  .page-product-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 769px) {
  .page-product-items-desktop {
    height: calc(100dvh - 8dvh - 75px - clamp(45px, 8.5dvh, 72px));
  }

  .page-product-items-desktop.search {
    width: 84%;
    height: calc(100dvh - 8dvh - 75px - clamp(45px, 8.5dvh, 72px));
  }

  .page-products-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .page-products-container-search {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start; 
    height: calc(100dvh + 8dvh + 80px);
  }

  .page-product-items {
    width: 84%;
    height: calc(100dvh - 8dvh - 80px - clamp(45px, 8.5dvh, 72px));
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 50%;
    row-gap: 10px;
    column-gap: 7px;
    padding: 5px 5px;
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .page-product-items-inline {
    width: 88%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 50%;
    row-gap: 0px;
    column-gap: 20px;
    padding: 10px 5px;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    box-sizing: border-box;
  }

  /* For Webkit browsers (e.g., Chrome, Safari) */
  .page-product-items::-webkit-scrollbar {
    width: 2px;
  }

  .page-product-items::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .page-product-items::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  .page-product-items::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.search-suggestions-title {
  color: #40454bc5;
  font-size: clamp(14px, 1.5dvh, 18px);
  font-weight: 300;
  text-transform: uppercase;
  padding-bottom: 12px;
}

.hud-search-desktop {
  width: 84%;
  margin-top: clamp( 45px, 8.5dvh,72px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-info-desktop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
}

.filters-and-group-by-desktop {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 250;
}

.filters-and-group-by-desktop-search {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 250;
}

.arrow-up-similar-products {
  position: absolute;
  left: 50%;
  bottom: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #f1f1f1;
}

::-webkit-scrollbar:vertical {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
}



@media screen and (min-width: 769px) {
  .filters {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 14px;
  }

  .searchbar-v2 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 769px) and (max-height: 640px) {
  .filters {
    margin-left: 0px;
    margin-right: 0px;
    font-size: 13px;
  }

  .searchbar-v2 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
