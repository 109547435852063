.score-container-headerv2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 63px;
}

.score-title-headerv2 {
    margin: 0;
    font-size: 13px;
    font-family: "Roboto";
    font-weight: normal;
    color: #40454b;
}

.score-value-headerv2 {
    margin: 0;
    color: #030;
    font-size: 24px;
    font-weight: bold;
    font-variant: small-caps;
}

@media (max-width: 300px) or (max-height: 680px) {
    .v2-product-header-container {
      height: 13dvh;
    }

    .score-value-headerv2 {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) and (max-height: 650px) {

    .score-title-headerv2 {
        font-size: 13px;
    }

    .score-value-headerv2 {
        font-size: 19px;
    }
}
  