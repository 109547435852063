canvas {
    pointer-events: none;
}

.composition-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.composition-title {
    color: #40454B;
    font-size: 13px;
    margin-top: 15px;
}

.composition-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-of-donut-container-v2 {
    display: flex;
    align-items: center;
    justify-content: center;

}



