.slidepage1-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.slidepage1-content-score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.slidepage1-content-composition {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}