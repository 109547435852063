@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.certification-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.image-container {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-image {
  width: 50px;
  height: 50px;
}

.item-image.default-icon {
  height: 40px;
}

.items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.item-title {
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  color: #40454B;
  font-size: 14px;
}

.description {
  font-family: 'Roboto';
  font-size: 10px;
  letter-spacing: 1.2px;
  color: #40454B;
}

@media (max-height: 690px) {
  .image-container {
    width: 30px;
    height: 30px;
  }

  .item-image {
    width: 30px;
    height: 30px;
  }
  
  .item-image.default-icon {
    height: 30px;
  }

  .item-title {
    font-size: 12px;
  }

    .description {
      font-size: 9px;
  }
}

@media (min-width: 769px) {
  .certification-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.2dvw;
    height: 65px;
  }

  .item-image {
    filter: saturate(0);
  }

  .item-title {
    font-size: 14px;
  }

    .description {
      font-size: 11px;
  }
}


