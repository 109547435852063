.careinstruction-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5em;
}

.careinstruction-img {
  width: 3.8dvh;
  height: 3.8dvh;
}

.careinstruction-description {
  color: #40454B;
  font-size: 1.7dvh;
  font-variant: small-caps;
}