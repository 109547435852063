/* container da Homepage page */
.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100dvh;
    max-width: 900px;
    overflow: hidden;
}

/* header -> logo */
.homepage-header {
    display: flex;
    width: 98%;
    max-width: 890px;
}

.homepage-title-searchbar-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    height: 40px;
}

.homepage-contentandproducts-slides-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 8px; /* alterado para 8px em vez de 5px se ficar apenas 1 linha no slideproducts */
    justify-content: space-evenly;
}

.homepage-slidecontent-container {
    width: 98%;
}

.homepage-slideproducts-container {
    width: 95%;
}

.homepage-footer-container {
    width: 88%;
}

.homepage-title-searchbar-page1 {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.homepage-title {
    color: #40454B;
    font-size: 20px;
    font-weight: bold;
    font-variant: small-caps;
}

.searchbar-img {
    width: 25px;
    height: 25px;
}



.homepage-title-searchbar-page2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

/* page2 - searchbar input para procurar valores */
.searchinput-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 75%;
    border: solid 2px #D1D1D1BF;
    border-radius: 5px;
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    gap: 8px;
    animation: moveFromRightComponent 0.4s ease-out;
}

@keyframes moveFromRightComponent {
    from {
      transform: translateX(2dvh);
      opacity: 0.5;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
}

.icon-searchinput {
    width: 15px;
    height: 15px;
}

.icon-searchinput-move {
    animation: moveFromRight 0.4s ease-out;
}

@keyframes moveFromRight {
    from {
      transform: translateX(20dvh);
      scale: 1.5;
    }
    to {
      transform: translateX(0);
      opacity: 1;
      scale: 1;
    }
}

.searchinput-value {
    height: 90%;
    border: none;
    outline: none;
    color: #40454B;
    font-size: 15px;
    font-weight: bold;
    font-variant: small-caps;
    width: 100%;
    background-color: transparent;
}

.searchinput-value::placeholder {
    color: #909191;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-variant: small-caps;
    text-align: center;
}
/* --------------------------------------------- */
