@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 100dvh;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    max-height: 100dvh;
  }
  to {
    opacity: 0;
    max-height: 0;
  }
}

.item-indicatorinfo {
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  transition: all 0.3s;
}

.item-indicatorinfo.collapsed .title-detailedIndex {
  cursor: pointer;
}

.item-indicatorinfo.expanded .title-detailedIndex  {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.container-detailedIndex.has-expanded .item-indicatorinfo {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.5;
  transform: scale(0.9);
  transform-origin: top left;
}

.container-detailedIndex-desktop.has-expanded .item-indicatorinfo.expanded {
  margin: 5px 0px;
  transition: opacity 0.3s ease;
  opacity: 1;
  transform: scale(1);
}

.container-detailedIndex-desktop.has-expanded .item-indicatorinfo {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0.5;
  transform: scale(0.9);
  transform-origin: top left;
}

.container-detailedIndex.has-expanded .item-indicatorinfo.expanded {
  margin: 5px 0px;
  transition: opacity 0.3s ease;
  opacity: 1;
  transform: scale(1);
}

.content-indicatorinfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: opacity 0.3s ease;
}

.item-indicatorinfo.collapsed {
  opacity: 1;
}

.icon {
  height: fit-content;
}

.icon.img {
  width: 27px;
  height: 27px;
}

.item-info {
  height: fit-content;
}

.icon-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.title-detailedIndex {
  font-size: 15.5px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #40454b;
}

.title-detailedIndex-text.expanded {
  text-decoration: underline;
  text-underline-offset: 3px;
  font-weight: bold;
}

.value {
  margin-left: 3px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0099cc;
}

.open-close {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.icon-index {
  width: 34px;
  height: 34px;
}

.description.expanded {
  display: block;
}

.extra-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.extra-content.expanded {
  animation: fadeIn 0.9s ease-out forwards;
}

.extra-content p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  color: #40454b;
}

.indicator-description {
  width: 80%;
}

@media (max-width: 300px), (max-height: 680px) {
  .icon-title {
    gap: 5px;
    height: fit-content;
  }

  .icon-index {
    width: 30px;
    height: 30px;
  }

  .title-detailedIndex {
    font-size: 14px;
  }

  .extra-content p {
    font-size: 11px;
  }
}

@media screen and (min-width: 769px), (min-height: 650) {
  .indicator-description {
    padding: 1dvh 0;
  }
}

@media screen and (min-width: 769px) {
  .content-indicatorinfo.expanded {
    gap: 7px;
  }

  .indicator-description {
    width: 100%;
  }

  .icon-index {
    width: clamp(30px, 2.8dvw, 40px);
    height: clamp(30px, 2.8dvw, 40px);
  }
  
  .title-detailedIndex {
    font-size: clamp(13px, 1dvw ,18px);
    font-family: "Roboto";
    font-weight: normal;
    color: #40454b;
  }

  .plus-sign {
    font-size: clamp(16px, 1dvw ,21px) !important;
    font-family: "Roboto";
    font-weight: normal;
    color: #666666;
  }

  .decription-preview-content-v2-p {
    width: 100%;
    font-size: clamp(11px, 1dvw ,15px) !important;
    color: #40454b;
    font-family: "Roboto";
    font-weight: normal;
    color: #40454b;
  }

  .extra-content p {
    font-family: "Roboto";
    margin: clamp(5px, 0.65dvh, 15px) 0;
    font-size: clamp(10px, 1dvw ,14px) !important;
  }

  .icon.img {
    width: 0px;
    height: 0px;
  }

  .value {
    font-size: clamp(13px, 1.3dvw , 20px);
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {

  .title-detailedIndex {
    font-size: 13px;
  }

  .extra-content p {
    font-size: 11px;
  }
}

