.score-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 63px;
}

.score-title {
    margin: 0;
    color: #40454B;
    font-size: 13px;
    font-weight: 700;
    font-variant: small-caps;
}

.score-value {
    margin: 0;
    color: #030;
    font-size: 24px;
    font-weight: 700;
    font-variant: small-caps;
}