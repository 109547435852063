/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
    position: absolute;
    width: 100%;
    height: 72.52px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 9.542px 9.542px 0px 0px;
    box-sizing: border-box;
    z-index:1001;
  }

  .navbar.ishomepage {
    background: transparent;
    box-shadow: none;
    border-top: 2px solid #FFFFFF99;
  }

  .navbar-left,
  .navbar-center,
  .navbar-right {
    display: flex;
    align-items: center;
  }

  .navbar-left.ishomepage,
  .navbar-center.ishomepage,
  .navbar-right.ishomepage {
    filter: invert(100%) brightness(100);
  }


  .navbar-left,
  .navbar-right {
    flex: 1; /* Cada um ocupa a mesma quantidade de espaço */
    justify-content: space-around; /* OU SPACE-EVENLY, NÃO SEI QUAL FICA MELHOR */
  }
  .navbar-center {
    /* flex: 2; */ /* CENTRO DEIXA DE OCCUPAR DOBRO DO ESPAÇO */
    justify-content: center;
  }

  .navbar-item {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10.4962px;
    line-height: 12px;
    display: flex;
    flex-direction: column; /* Organiza os itens em coluna */
    align-items: center; /* Centraliza os itens horizontalmente */
    text-align: center;
    color: #666666;
    text-decoration: none;
/*     padding: 0 10px;  TINHA PADDING À DIREITA*/ 
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .navbar-item.ishomepage {
    color: #000000;
    font-weight: 600;
  }

  .navbar-item-center {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10.4962px;
    line-height: 12px;
    display: flex;
    flex-direction: column; /* Organiza os itens em coluna */
    align-items: center; /* Centraliza os itens horizontalmente */
    text-align: center;
    color: #666666;
    text-decoration: none;
    padding: 0 10px;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .navbar-item-center.ishomepage {
    color: #000000;
  }

  .navbar-item img {
    height: 25px;
    margin-bottom: 5px;
  }
}

/* Ocultar navbar em dispositivos maiores */
@media (min-width: 769px) {
  .navbar {
    display: none;
  }
}
