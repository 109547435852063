@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

.carousel-container-aditional {
  display: flex;
  width: 96%;
  justify-content: center;
  max-height: 200px;
  z-index: 200;
}

.stacked-carousel-responsive-container {
  display: flex;
  flex-direction: column;
}

.carousel-loading .stacked-carousel-slide {
  transition: none !important;
  animation: none !important;
}

.carousel-container-aditional {
  opacity: 0;
  animation: growOpacity 0.6s ease-in forwards;
}

@keyframes growOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.react-stacked-center-carousel-slide--2 {
  transition: all 0ms ease 0s, z-index 0ms ease 0s !important;
}

.react-stacked-center-carousel-slide--1 {
  transition: all 50ms ease 0s, z-index 0ms ease 0s !important;
}

.react-stacked-center-carousel-slide-0 {
  transition: all 150ms ease 0s, z-index 0ms ease 0s !important;
}

.react-stacked-center-carousel-slide-1 {
  transition: all 50ms ease 0s, z-index 0ms ease 0s !important;
}

.react-stacked-center-carousel-slide-2 {
  transition: all 0ms ease 0s, z-index 0ms ease 0s !important;
}

.slideproducts-carousel-maindiv {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.slideproperty-image img {
  width: 32px;
  height: 32px;
}

.slideproperty-image-opacity img {
  width: 27px;
  height: 27px;
  opacity: 0.7;
}

.slideproduct-carousel-name {
  font-family: "Roboto";
  font-style: normal;
  font-variant: normal !important;
  font-weight: bold;
  font-size: 20px;
  line-height: 15px;
  text-decoration: underline;
  color: #666666;
  text-underline-offset: 4px;
  padding-bottom: 4px;
}

.carousel-container .material-button-left {
  box-shadow: none;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  background-color: transparent !important;
  z-index: 5;
}

.carousel-container .material-button-right {
  color: #666666;
  box-shadow: none;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  background-color: transparent !important;
  z-index: 5;
  right: 0;
}

.slideproduct-carousel-texts {
  display: flex;
  justify-content: center;
}

.slideproduct-carousel-name {
  padding-top: 2px;
}

.title-info-fixed {
  display: flex;
  justify-content: center;
}

@media (max-width: 480px) {
  .slideproduct-carousel-name {
    padding-bottom: 4px;
  }

  .slideproduct-carousel-image img {
    width: 50px;
    height: 50px;
    background-color: transparent !important;
  }
  .slideproperty-image-opacity img {
    width: 43px;
    height: 43px;
    opacity: 0.7;
  }
  .slideproduct-carousel-name {
    font-size: 15px;
  }

  .slideproduct-carousel-name {
    padding-bottom: 4px;
  }
}

.title-container-aditional {
  display: flex;
  width: 99%;

  justify-content: space-evenly;
}

.react-stacked-center-carousel {
  max-height: 54px;
}

@media (min-width: 480px) and (max-width: 768px) {
  .slideproduct-carousel-image img {
    width: 45px;
    height: 45px;
    background-color: transparent !important;
  }
  .slideproperty-image-opacity img {
    width: 38px;
    height: 38px;
    opacity: 0.7;
  }
  .slideproduct-carousel-name {
    font-size: 16px;
  }

  .slideproduct-carousel-name {
    padding-bottom: 4px;
    text-underline-offset: 5px;
  }
}

@media (min-width: 769px) {

  .carousel-container-aditional {
    width: 100%;
    max-height: 200px;
    z-index: 200;
  }

  .react-stacked-center-carousel-slide--1 {
    transform: translateX(-10dvw) scale(1) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-stacked-center-carousel-slide-0 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-stacked-center-carousel-slide-1 {
    transform: translateX(50dvw) scale(1) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slideproduct-carousel-image img {
    width: 50px;
    height: 50px;
    background-color: transparent !important;
  }
  .slideproperty-image-opacity img {
    width: 40px;
    height: 40px;
    opacity: 0.7;
  }
  .slideproduct-carousel-name {
    font-size: 15px;
  }
  .slideproduct-carousel-name {
    padding-bottom: 4px;
    text-underline-offset: 5px;
  }
}
