.div-map-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    height: fit-content;
    opacity: 0;
    animation: contentAppear 0.3s ease-in forwards;
    cursor: pointer;
}
  
  @keyframes contentAppear {
    to {
        opacity: 1;
    }
  }

.div-map-container.expanded {
    height: 100%;
}

.rsm-svg {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2));
    z-index: 5;
}

.overlay.collapsed {
    opacity: 1;
}

.overlay.expanded {
    display: none;
    pointer-events: none;
}

.icon-collapse-expand {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: 3px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 999;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.click-to-expand-container {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    z-index: 1001;
}

.icon-collapse-expand-new {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    cursor: pointer;
    z-index: 999;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.click-to-expand-text {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    font-family: "Roboto";
    color: #003366;
    text-shadow: 0px 0px 10px #00336670;
}

button {
    display: flex;
    align-items: center;
    background-color: red;
    position: absolute;
    top: 4px;
    right: 0;
    border: none;
    cursor: pointer;
    z-index: 999;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.overlay-group-container {
    bottom: 5px;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.overlay-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.overlay-group-value {
    font-size: 20px;
    font-weight: bold;
    color: #003366;
    font-variant: small-caps;
}

.overlay-group-value.distance {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 4px;
}

.overlay-group-unit {
    font-size: 12px;
    color: #003366;
    font-variant: small-caps;
    margin-bottom: 2px;
}

.overlay-group-text {
    font-size: 15px;
    color: #003366cc;
    font-variant: small-caps;
}

.overlay-group-text::first-letter {
    font-size: 82%; 
}

.vertical-line-map {
    height: 50px;
    width: 1px;
    background-color: #001e3d1e;
}

@media (max-width: 330px) {
    .overlay-group-value {
        font-size: 15px;
    }
  
    .overlay-group-text {
        font-size: 12px;
    }
}

@media screen and (min-width: 769px) {
    .icon-collapse-expand {
        width: clamp( 20px, 2.5dvw, 30px);
        height: clamp( 20px, 2.5dvw, 30px);
    }

    .click-to-expand-container {
        top: 35%;
    }
    
    .icon-collapse-expand-new {
        width: clamp( 20px, 2.5dvw, 30px);
        height: clamp( 20px, 2.5dvw, 30px);
    }
    
    .click-to-expand-text {
        font-size: clamp( 15px, 1.9dvw, 16px);
        text-shadow: 0px 0px 0px #00336670;
    }

    .overlay-group-unit {
        font-size: clamp( 20px, 2dvw, 24px);
        margin-bottom: 2px;
    }

    .overlay-group-value {
        font-size: clamp( 20px, 2dvw, 24px);
    }
    
    .overlay-group-text {
        font-size: clamp( 15px, 1.9dvw, 18px);
    }
    
    .vertical-line-map {
        height: 50px;
        width: 1px;
        background-color: #001e3d1e;
    }

    .div-map-container {
        position: relative;
        width: 100%;
        max-width: 700px;
        border-radius: 5px;
        height: 38dvh;
        opacity: 0;
        animation: contentAppear 0.3s ease-in forwards;
    }
}

@media (min-width: 769px) and (max-height: 640px) {
    .icon-collapse-expand {
        width: clamp( 22px, 1.5dvw, 30px);
        height: clamp( 22px, 1.5dvw, 30px);
    }

    .click-to-expand-container {
        left: 50%;
    }
    
    .icon-collapse-expand-new {
        width: clamp( 22px, 1.5dvw, 30px);
        height: clamp( 22px, 1.5dvw, 30px);
    }
    
    .click-to-expand-text {
        font-size: clamp( 13px, 1dvw, 15px);
        text-shadow: 0px 0px 0px #00336670;
    }

    .overlay-group-unit {
        font-size: clamp( 18px, 1.2dvw, 24px);
        margin-bottom: 2px;
    }

    .overlay-group-value {
        font-size: clamp( 18px, 1.2dvw, 24px);
    }
    
    .overlay-group-text {
        font-size: clamp( 15px, 1dvw, 18px);
    }
    
    .vertical-line-map {
        height: 50px;
        width: 1px;
        background-color: #001e3d1e;
    }

    .div-map-container {
        position: relative;
        width: 100%;
        max-width: 700px;
        border-radius: 5px;
        height: 26dvh;
        opacity: 0;
        animation: contentAppear 0.3s ease-in forwards;
    }
}
