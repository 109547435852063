@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.score-container {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
}

.score-container-desktop {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: fit-content;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.score-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.score-header-desktop {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.score-icon-af {
  width: 35px;
  height: 35px;
}

.score-title-v2 {
  font-size: 15px;
  font-family: "Roboto";
  font-weight: normal;
  color: #40454b;
}

.score-value {
  font-size: 21px;
  font-family: "Roboto";
  font-weight: bold;
}

.score-value.color-1 {
  color: #59c0e9;
}

.score-value.color-2 {
  color: #00a2c9;
}

.score-value.color-3 {
  color: #00a3b2;
}

.score-value.color-4 {
  color: #006e8f;
}

.score-value.color-5 {
  color: #002a69;
}

.score-value.color-6 {
  color: #001c4a;
}

.score-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.score-info-desktop {
  width: 100%;
}

.separator-container {
  width: 48px;
  display: flex;
  justify-content: end;
}

.separator {
  width: 1px;
  height: 100%;
  background-color: #6666661e;
}

.score-grades-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}

.score-grades-description-desktop {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}

.score-grades {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.score-grades-desktop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-description {
  width: 100%;
  font-size: 13px;
  color: #40454b;
  font-family: "Roboto";
  font-weight: normal;
  color: #40454b;
}

@media (max-width: 300px) or (max-height: 750px) {

  .score-title-v2 {
    font-size: 14px;
  }

  .score-value {
    font-size: 18px;
  }

  .separator-container {
    width: 40px;
  }

  .score-description {
    font-size: 13px;
  }

  .score-grades-description {
    gap: 8px;
  }
}

@media (max-width: 300px) or (max-height: 680px) {

  .score-icon-af {
    width: 28px;
    height: 28px;
  }

  .score-title-v2 {
    font-size: 13px;
  }

  .score-value {
    font-size: 17px;
  }

  .separator-container {
    width: 40px;
  }

  .score-description {
    font-size: 13px;
  }

  .score-grades-description {
    gap: 8px;
  }
}

@media screen and (min-width: 769px) {
  .score-icon-af {
    width: clamp(30px, 2.8dvw, 40px);
    height: clamp(30px, 2.8dvw, 40px);
  }

  .score-grades-desktop {
    width: calc(100% - clamp(35px, 2.7dvw, 40px) - 3px);
    display: flex;
    align-items: center;
    justify-content: start;
  }

    .score-grades-desktop.p0 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .score-header-desktop {
    gap: 10px;
  }
  
  .score-title-v2 {
    font-size: clamp(14px, 1.2dvw ,18px);
    font-family: "Roboto";
    font-weight: normal;
  }
  
  .score-value {
    font-size: clamp(21px, 1.2dvw ,27px);
    font-family: "Roboto";
    font-weight: bold;
  }

  .score-grades-description-desktop {
    align-items: flex-start;
    gap: clamp(5px, 1dvw, 15px);
  }

  .separator-container {
    width: calc(clamp(30px, 2.8dvw, 40px) + 15px );
    display: flex;
    justify-content: end;
  }

  .score-grades-description-desktop-full {
    display: flex;
    gap: 7px;
  }
  

  .separator {
    width: 1px;
    height: 100%;
    background-color: #6666661e;
  }

  .score-description {
    width: 100%;
    font-size: clamp(11px, 1dvw ,15px);
    color: #40454b;
    font-family: "Roboto";
    font-weight: normal;
    color: #40454b;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {

  .score-icon-af {
    width: 30px;
    height: 30px;
  }

  .score-title-v2 {
    font-size: 13px;
  }

  .score-value {
    font-size: 17px;
  }

  .separator-container {
    width: 40px;
  }

  .score-description {
    font-size: 11px;
  }

  .score-grades-description {
    gap: 4px;
  }
}