@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.icon-scale {
  display: flex; 
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.weight-scale {
  display: flex;
  flex-direction: row;
  font-family: 'Roboto';
  justify-content: center;
  gap: 3.8em;
  font-weight: bold;
  line-height: 12.89px;
  color: #40454B66;
  min-width: 11.8em;
}

.scales-1,
.scales-2,
.scales-3 {
  font-size: 7px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  line-height: 11px;
  text-align: center;
  color: rgba(64, 69, 75, 0.4);
}

.scales-1.selected,
.scales-2.selected,
.scales-3.selected {
  font-weight: bold;
  color: #0099CC;
}

.icon-row {
  display: flex;
  flex-direction: row;
  margin-left: 0px;
}

.icon-wrapper {
  display: flex;
  margin: 0 0.6rem;
  transition: transform 0.2s;
  flex-direction: row;
  justify-content: center;
}

.icon-wrapper.selected {
  transform: scale(1.4);
  margin-bottom: 5px;
}


.icon-wrapper.level-0 { filter: brightness(100%); }
.icon-wrapper.level-1 { filter: brightness(100%); }
.icon-wrapper.level-2 { filter: brightness(90%); }
.icon-wrapper.level-3 { filter: brightness(80%); }
.icon-wrapper.level-4 { filter: brightness(70%); }
.icon-wrapper.level-5 { filter: brightness(60%); }
.icon-wrapper.level-6 { filter: brightness(50%); }
.icon-wrapper.level-7 { filter: brightness(40%); }
.icon-wrapper.level-8 { filter: brightness(30%); }
.icon-wrapper.level-9 { filter: brightness(20%); }


.icon-wrapper.opacity-1 { filter: grayscale(40%); opacity: 0.15; }
.icon-wrapper.opacity-2 { filter: grayscale(40%); opacity: 0.15; }
.icon-wrapper.opacity-3 { filter: grayscale(45%); opacity: 0.2; }
.icon-wrapper.opacity-4 { filter: grayscale(50%); opacity: 0.25;}
.icon-wrapper.opacity-5 { filter: grayscale(55%); opacity: 0.3; }
.icon-wrapper.opacity-6 { filter: grayscale(60%); opacity: 0.4; }
.icon-wrapper.opacity-7 { filter: grayscale(65%); opacity: 0.5; }
.icon-wrapper.opacity-8 { filter: grayscale(75%); opacity: 0.6; }
.icon-wrapper.opacity-9 { filter: grayscale(80%); opacity: 0.7; }

/* Media Queries */

@media (max-width: 468px) {
  .icon-scale {
    gap: 7px;
  }

  .weight-scale {
    font-size: calc(7px * 1.3);
    line-height: calc(11px * 1.2);
    gap: 8.2em;
  }
  .icon-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .scales-1,
  .scales-2,
  .scales-3 {
    font-size: calc(7px * 1.2);
    line-height: calc(11px * 1.2);
  }

  .icon-wrapper {
    margin: 0  calc(0.6rem * 1.1);
  }

  .icon-image {
    width: calc(13px * 1.0); 

  }
}


@media (min-width: 768px) {
  .icon-scale {
    width: 60%;
    max-width: 500px;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: clamp(5px, 1dvw, 15px);
    padding-top: 10px;
  }

  .weight-scale {
    width: 94%;
    gap: 1px;
    justify-content: space-between;
  }

  .icon-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
  }

  .scales-1,
  .scales-2,
  .scales-3 {
    font-size: clamp(10px, 0.9dvw ,15px) !important;
  }

  .icon-wrapper {
    margin: 0;
  }

  .icon-image {
    width: clamp(13px, 1.5dvw, 25px);
    height: clamp(13px, 1.5dvw, 25px);
  }
}
