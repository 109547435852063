.swipe-panel-similar-products {
  position: fixed;
  bottom: 62px;
  left: 0;
  right: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out;
  background-color: white;
  z-index: 1000;
  height: 356px;
}

.swipe-panel-similar-products-desktop {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  background-color: white;
  z-index: 1000;
  height: 356px;
}

.overlay-products-similar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: all;
  z-index: 999;
  opacity: 0;
  animation: growOpacitySimilar 0.2s ease-in forwards;
}

@keyframes growOpacitySimilar {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.7;
  }
}

.swipe-panel-similar-products.closed {
  transform: translateY(60%);
}

.swipe-panel-similar-products.closed-desktop {
  transform: translateY(30dvh);
}

.swipe-panel-similar-products-desktop.always-open.open-desktop {
  transform: translateY(100%);
}

.swipe-panel-similar-products-desktop.always-open.closed-desktop {
  transform: translateY(0%);
  z-index: 2000;
}

.container-similar-products {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-images-similar-products-v2 {
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  justify-items: center;
  z-index: 800;
  padding: 0 5px;
}

.container-title-similar-products {
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #40454b;
  z-index: 800;
  line-height: 17px;
}

.product-item-similar-products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  z-index: 800;
  object-fit: cover;
}

.arrow-container-similar-products {
  position: absolute;
  top: -11px;
  left: calc(50% - 12.5px);
  background-color: white;
  width: 25px;
  height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.25);
}

.arrow-container-similar-products img {
  height: 10px;
  width: 10px;
}

@media screen and (max-height: 768px) and (min-width: 768px) {
  .container-similar-products {
    width: 82%;
  }
}

@media screen and (min-width: 769px) {
  .arrow-container-similar-products {
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.17);
    left: calc(50% - 25px);
    top: -12px;
    width: 50px;
    height: 15px;
    cursor: pointer;
  }

  .container-title-similar-products {
    font-size: clamp(14px, 13px + 0.2dvh, 17px);
    color: #4a4a4a !important;
    font-family: 'Roboto' !important;
    font-weight: 300 !important;
    line-height: 18px !important;
  }

  .arrow-container-similar-products img {
    height: 16px;
    width: 16px;
    cursor: pointer;
    filter: brightness(0.75);
  }

  .container-images-similar-products-v2 {
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: clamp(0.5dvh, 0.5dvh + 100px, 1dvh);
    gap: 1.7dvw;
    height: 100%;
  }

  .container-similar-products {
    justify-content: center;
    width: 65%;
    justify-content: center;
    align-items: center;
  }

  .product-item-similar-products {
    width: 100%;
    height: 35dvh;
    min-height: 200px;
  }

  .swipe-panel-similar-products-desktop {
    position: fixed;
    height: auto;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .swipe-panel-similar-products {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    z-index: 2002;
    height: auto;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: rgba(255, 255, 255, 0.94);
    
  }

  .swipe-panel-similar-products.closed {
    transform: translateY(98%);
  }

  .overlay-products-similar {
    z-index: 2001;
    cursor: pointer;
  }

  @media screen and (min-width: 769px) and (max-width: 1280px) {
    .container-similar-products {
      width: 80%;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  
  .swipe-panel-similar-products.closed {
    transform: translateY(70%);
  }
  
  .container-title-similar-products {
    font-size: 13px;
  }
}
