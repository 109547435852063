.v2-product-header-container {
  display: flex;
  flex-direction: row;
  height: 14dvh;
  width: 100%;
  max-width: 900px;
  border-radius: 5px;
  border: 2px solid #D1D1D180;
  padding: 0;
}

.v2-product-header-container.without-score {
  height: 12dvh;
}

.v2-product-header-img {
  z-index: 999;
  width: 60%;
  border-radius: 4px 0px 0px 4px;
  object-fit: cover;
}

.v2-image-fade-header-enter {
  opacity: 0;
}

.v2-image-fade-header-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.v2-image-fade-header-exit {
  opacity: 1;
}

.v2-image-fade-header-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.v2-product-header-img-placeholder {
  width: 50%;
  height: 100%;
  background-color: #f0f0f0;
}

.v2-product-header-text-score-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;
  animation: contentAppear 0.5s ease-out forwards;
}

@keyframes contentAppear {
  to {
    opacity: 1;
  }
}

.v2-product-header-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v2-product-header-text-name-v2 {
  color: #40454B;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}

.v2-product-header-text-name-v2.madein {
  scale: 0.9;
}

.v2-product-header-text-brand-v2 {
  color: #666;
  font-size: 11px;
  text-align: center;
}

@media screen and (min-width: 400px) {
  .v2-product-header-text-name-v2 {
    font-size: 14px;
  }

  .v2-product-header-text-brand-v2 {
    font-size: 12px;
  }
}

@media (max-width: 300px) or (max-height: 680px) {
  .v2-product-header-container {
    height: 13dvh;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .v2-product-header-img {
    width: 50%;
  }

  .v2-product-header-text-name-v2 {
    font-size: 11px;
  }

  .v2-product-header-text-brand-v2 {
    font-size: 10px;
  }
}
