.my-slide-component {
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: auto;
    transition: opacity 0.3s ease;
}

.slideproducts-carousel-title {
    color: #40454B;
    font-size: 15px;
    font-weight: bold;
    font-variant: small-caps;
}

.slideproduct-carousel-texts {
    width: max-content;
    position: absolute;
    bottom: -3.5dvh;
}
  
  .slideproduct-carousel-image {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
}
  
.slideproduct-carousel-image img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    background-color: #f0f2f5;
}

.react-stacked-center-carousel {
    height: 15.5dvh !important;
}

@media screen and (max-width: 320px), (max-height: 740px) {
    .slideproduct-carousel-image img {
        width: 90px;
        height: 90px;
    }
}

@media screen and (max-width: 281px), (max-height: 720px) {
    .slideproduct-carousel-image img {
        width: 85px;
        height: 85px;
    }
}

@media screen and (max-width: 251px), (max-height: 695px) {
    .slideproduct-carousel-image img {
        width: 75px;
        height: 75px;
    }
}
  
  .slideproduct-carousel-name {
    color: #40454B;
    font-size: 1.5dvh;
    font-variant: small-caps;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
}

.slideproduct-carousel-brand {
    color: #666;
    font-size: 1.25dvh;
    font-variant: small-caps;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
}


.slideproducts-carousel-maindiv {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}