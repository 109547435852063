@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.outer-container {
  right: calc(3.94% + 0px);
  top: 22.5px;
  position: fixed;
  display: flex;
  width: fit-content;
}

.container-certifications-v2 {
  overflow: hidden;
  border: 1px solid #ccc;
  backdrop-filter: blur(2px);
  border-radius: 9px 0px 0px 9px;
  right: calc(3.94% + 0px);
  top: 65px;
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  max-height: 90px;
  width: 10px;
}

.container-certifications-v2.collapsed {
  background-color: #ffffffde;
  width: 34px;
  min-height: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-certifications-v2.expanded {
  background-color: #ffffffde;
  width: 80dvw;
  max-height: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  transition: max-height 0.3s ease-out, width 0.3s ease-out;
  padding: 10px 0;
}


.container-certifications-v2.full-expanded {
  background-color: #ffffffde;
  width: 70dvw;
  max-width: 500px;
  max-height: 50%;
  display: flex;
  flex-direction: column;
}

.edge {
  position: absolute;
  left: -47px;
  top: 70px;
  width: fit-content;
  height: 25px;
  background-color: #ffffffde;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  border-radius: 5px 0px 0px 5px;
  border-left: 1px solid #cccccc80;
  border-top: 1px solid #cccccc80;
  border-bottom: 1px solid #cccccc80;
}

.arrow-icon-edge {
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
  filter: brightness(40%);
}

.container-certifications-v2.expanded .edge {
  display: none;
}

.image-container-first {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.div-estranha {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-blabla-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-blabla {
  width: 28px;
  height: 28px;
}

.horizontal-line {
  width: 80%;
  height: 1px;
  border-radius: 5px;
  background-color: #666666;
}

.title-certifications-v2 {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  color: #40454B;
}

.icon-close-certifications {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5px;
}

.title-certifications-v2 img {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.certifications {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-height: 400px;
  padding: 10px 3px;
}

.certifications.nocert {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
}

.certifications-estranha {
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-certifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-certifications-icon {
  width: 50px;
  height: 50px;
}

.no-certifications p {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  color: #40454B;
  text-align: center;
}

.certifications-map {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.certification-item {
  display: flex; 
  align-items: flex-start;
}

.see-more-container {
  text-align: center;
  width: 100%;
}

.see-more-text {
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
  color: #40454B;
  font-family: 'Roboto', sans-serif;
}

.composition-container-cert {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.composition-container-cert-p {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  color: #40454B;
}

/* quando se carrega no see more (full expanded) */

.title-full {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  color: #40454B;
}

.title-full img {
  position: fixed;
  cursor: pointer;
  left: 1.5vh;
  height: 2.5vh;
  width: auto;
}

.certificationss-expanded {
  width: 94%;
  height: 60vh; /* Full viewport height */
  overflow: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
  touch-action: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.certification-item-expanded {
  display: flex; 
  align-items: flex-start;
}

@media (max-height: 690px) {
  .certifications {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .certifications-map {
    gap: 5px;
  }
}
