.composition-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.composition-line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-of-donut-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.composition-title-desktopp {
    font-family: "Roboto";
    font-size: clamp(13px, 1.7dvw, 16px);
    font-weight: 300;
    line-height: 18px;
    text-align: center;
    color: #010101;
}