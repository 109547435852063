.productgallery-container {
  position: relative;
  width: 94%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 9px;
  opacity: 0;
  animation: contentAppear 100ms ease-in forwards;
  height: 100%;
  overflow: hidden;
}

@keyframes contentAppear {
  to {
    opacity: 1;
  }
}

.img-main-produto {
  width: calc(100% - 4px);
  height: 63dvh;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #D1D1D180;
}

.img-thumbnails {
  left: 4%;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0px;
}

.img-thumbnail {
  opacity: 0.5;
  background-color: white;
  border-radius: 5px;
  width: 5dvw;
  min-width: 40px;
  max-width: 60px;
  height: 40px;
  object-fit: cover;
  cursor: pointer;
  transition: opacity 0.3s;
}

.img-thumbnail.active {
  opacity: 1;
  border: 2px solid #000;
}

/* Scrollbar styling */
.scroll-bar {
  width: 2px;
  height: 100%;
  background: #f1f1f1;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroll-bar-item {
  width: 100%;
  height: 100%;
  background-color: #cbcbcb;
  border-radius: 5px;
}

.scroll-bar-item.active {
  background: #555;
  border-radius: 5px;
}

@media screen and (min-width: 769px) {
  .productgallery-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding-top: 0px;
    gap: 1.8dvw;
  }

  .img-main-produto {
    width: 46dvw;
    height: calc(100% - 4px);
    border-radius: 5px;
    margin-left: 0dvw;
  }

  .img-thumbnails {
    width: clamp(70px, 4.3dvw, 85px);
    left: 0%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px;
  }

  .img-thumbnail {
    opacity: 0.7;
    width: clamp(70px, 4.3dvw, 85px);
    min-width: 70px;
    max-width: 85px;
    height: clamp(70px, 4.3dvw, 85px);
    object-fit: cover;
  }
}

@media screen and (max-width: 768px) and (max-height: 650px) {
  .img-main-produto {
    height: 60dvh;
  }
}
