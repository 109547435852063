.product-header-container {
    display: flex;
    flex-direction: row;
    height: 16.785dvh;
    width: 95%;
    max-width: 900px;
    border-radius: 5px;
    border: 2px solid transparent;
    animation: borderAnimation 0.5s ease-out forwards;
}

@keyframes borderAnimation {
  to {
      border-color: #D1D1D180;
  }
}


.product-header-img {
    z-index: 999;
    width: 60%;
    border-radius: 5px;
}

.image-fade-header {
  background-color: white;
}

.image-fade-header-enter {
  z-index: 999;
  opacity: 0.25;
  border-radius: 5px;
  transform: translate(10dvh,25dvh) scale(1.8, 4);
  object-fit: cover;
}

.image-fade-header-enter-active {
  z-index: 999;
  opacity: 1;
  transform: scale(1); /* Scale up to 1 */
  transition: opacity 300ms, transform 200ms ease-out;
}

.image-fade-header-exit {
  opacity: 1;
}


.product-header-text-score-container {
    display: flex;
    width: 40%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    opacity: 0;
    animation: contentAppear 0.5s ease-out forwards;
}

@keyframes contentAppear {
  to {
      opacity: 1;
  }
}

.product-header-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-header-text-name {
    color: #40454B;
    font-size: 13px;
    font-weight: 500;
    font-variant: small-caps;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 100%;
}

.product-header-text-brand {
    color: #666;
    font-size: 11px;
    font-variant: small-caps;
    text-align: center;
}

@media screen and (min-width: 400px) {
  .product-header-text-name {
    font-size: 14px;
  }

  .product-header-text-brand {
    font-size: 12px;
  }
}